<script lang="ts">
	import Input from '@isoftdata/svelte-input'
	import { createEventDispatcher } from 'svelte'
	//@ts-ignore
	import financialNumber from 'financial-number'

	export let value: string = '0.00'
	let displayValue = value
	export let input: HTMLInputElement | undefined = undefined

	const dispatch = createEventDispatcher<{ input: string; change: string }>()
	const formatCurrency = (value: string) => {
		let replacedValue = (value.replace(/[^0-9.]/g, '') || '0').trim()
		if (replacedValue.endsWith('.')) {
			replacedValue = `${replacedValue}00`
		}
		return financialNumber(replacedValue).toString()
	}

	function onInput(event: Event) {
		if (!(event.target instanceof HTMLInputElement)) {
			return
		}
		value = formatCurrency(event.target.value)
		dispatch('input', value)
	}

	function onChange(event: Event) {
		if (!(event.target instanceof HTMLInputElement)) {
			return
		}
		value = formatCurrency(event.target.value)
		displayValue = value
		dispatch('change', value)
	}

	function updateDisplayValue(value: string) {
		if (document.activeElement !== input) {
			displayValue = formatCurrency(value)
		}
	}

	$: updateDisplayValue(value)
</script>

<Input
	inputmode="decimal"
	selectOnFocus
	bind:value={displayValue}
	on:input={onInput}
	on:change={onChange}
	{...$$props}
	bind:input
>
	<span
		slot="prepend"
		class="input-group-text">$</span
	>
</Input>
