<script lang="ts">
	import type { Mediator, SvelteAsr } from 'types/common'
	import DateRange from '@isoftdata/svelte-date-range'
	import { type ComponentProps, type ComponentEvents } from 'svelte'
	import getEventValue from 'utility/get-event-value'
	import { toLocaleDateString } from '@isoftdata/utility-date-time'
	import { format as formatCurrency } from '@isoftdata/utility-currency'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import makeStorageHelper from 'utility/storage-json-helper'

	export let asr: SvelteAsr
	export let range: ComponentProps<DateRange>['range']
	export let dates: ComponentProps<DateRange>['dates']
	export let isActive: string
	export let doSalespersonFilter: boolean

	import Table, { type Column, Td } from '@isoftdata/svelte-table'
	import { getContext } from 'svelte'
	import NoItemsForSelectedFilter from 'components/NoItemsForSelectedFilter.svelte'
	import type { Document } from 'types/common'
	interface DisplayDoccument extends Document {
		purchaseOrderId: string | number | undefined
	}
	export let displayDocumentList: Array<DisplayDoccument>

	const mediator = getContext<Mediator>('mediator')
	const localStorageJSON = makeStorageHelper('localStorage')
	const session = localStorageJSON.getItem('session')

	async function dateChange(event: ComponentEvents<DateRange>['change']) {
		const dates = event.detail.dates
		asr.go(null, { fromDate: dates.from, toDate: dates.to }, { inherit: true })
		if (range !== 'Custom') {
			await mediator.call('emitToServer', 'save user setting', {
				name: 'Chromium Home Screen Date Range',
				category: 'Home',
				defaultValue: 'Last 7 Days',
				settingType: 'Interface History',
				value: range,
			})
		}
	}

	function isActiveChange(event: Event) {
		const value = getEventValue(event)
		// isActive filtering works by either being `true`, `false`, or not set for no filtering.
		asr.go(null, { isActive: value !== 'undefined' ? value : undefined }, { inherit: true })
	}

	function salespersonFilterChange() {
		asr.go(null, { salesperson: doSalespersonFilter ? session?.userName : undefined }, { inherit: true })
	}

	const columns: Array<Column> = [
		{ property: 'quoteId', name: 'Work Order #', numeric: true, width: '1rem' },
		{ property: 'documentDate', name: 'Date', defaultSortColumn: true, defaultSortDirection: 'DESC' },
		{ property: 'billTo', name: 'Bill To' },
		{ property: 'shipTo', name: 'Ship To' },
		{ property: 'subtotal', name: 'Subtotal', numeric: true },
		{ property: 'tax', name: 'Tax', numeric: true },
		{ property: 'total', name: 'Total', numeric: true },
		{ property: 'salesperson', name: 'Salesperson' },
		{ property: 'comments', name: 'Comments' },
	]
</script>

<div class="card-body p-2 card-body-p2">
	<div class="form-row card-body-p2-form-row">
		<div class="col-md-3 col-lg-2">
			<div
				class="card"
				style="margin-bottom: 20px;"
			>
				<h5 class="card-header">Filters</h5>
				<div class="card-body p-2">
					<DateRange
						bind:range
						bind:dates
						on:change={dateChange}
					></DateRange>
					<hr />
					<div class="w-100 form-group mb-1">
						<fieldset>
							<legend style="font-size: unset">Active</legend>
							<div
								class="form-control"
								style="height: 100%;"
							>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="activeFilter"
										id="activeFilterUnfiltered"
										value="undefined"
										bind:group={isActive}
										on:change={isActiveChange}
									/>
									<label
										class="form-check-label"
										for="activeFilterUnfiltered">All</label
									>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="activeFilter"
										id="activeFilterActive"
										value="true"
										bind:group={isActive}
										on:change={isActiveChange}
									/>
									<label
										class="form-check-label"
										for="activeFilterActive">Active</label
									>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										name="activeFilter"
										id="activeFilterInactive"
										value="false"
										bind:group={isActive}
										on:change={isActiveChange}
									/>
									<label
										class="form-check-label"
										for="activeFilterInactive">Inactive</label
									>
								</div>
							</div>
						</fieldset>
					</div>
					<hr />
					<Checkbox
						label="Salesperson"
						type="radio"
						trueLabel="Me"
						falseLabel="Anyone"
						bind:checked={doSalespersonFilter}
						on:change={salespersonFilterChange}
					/>
				</div>
			</div>
		</div>
		<div
			class="col-md-9 col-lg-10 tab-pane"
			id="sales"
			role="tab"
		>
			{#if displayDocumentList.length > 0}
				<Table
					responsive
					{columns}
					perPageCount={15}
					rows={displayDocumentList}
					let:row
				>
					<tr
						on:click={() => asr.go('app.sale', { documentId: row.quoteId, documentType: 'WORK_ORDER' })}
						class="cursor-pointer"
					>
						<Td property="quoteId">{row.quoteId}</Td>
						<Td property="documentDate">{toLocaleDateString(row.documentDate)}</Td>
						<Td property="billTo">{row.billTo}</Td>
						<Td property="shipTo">{row.shipTo}</Td>
						<Td property="subtotal">{formatCurrency(row.subtotal)}</Td>
						<Td property="tax">{formatCurrency(row.tax)}</Td>
						<Td property="total">{formatCurrency(row.total)}</Td>
						<Td property="salesperson">{row.salesperson}</Td>
						<Td property="comments">{row.comments ?? ''}</Td>
					</tr>
				</Table>
			{:else}
				<div class="well">
					<NoItemsForSelectedFilter>Work Orders</NoItemsForSelectedFilter>
				</div>
			{/if}
		</div>
	</div>
</div>
