<script lang="ts">
	import type { InterchangeWithPricing } from 'server/db/interchange-db'
	import type { InventoryType } from 'server/db/inventory-type-db'
	import type { Mediator, SvelteAsr } from 'types/common'
	import type { FileAssociation, FileItem } from 'server/db/file-db'

	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import Textarea from '@isoftdata/svelte-textarea'
	import Attachments from '@isoftdata/svelte-attachments'
	import Button from '@isoftdata/svelte-button'
	import ReportJobModal from './ReportJobModal.svelte'
	import Modal from '@isoftdata/svelte-modal'

	import { format as currencyFormat } from '@isoftdata/utility-currency'
	import interchangeLookup from 'utility/interchange-lookup'
	import { createEventDispatcher, getContext, type ComponentProps } from 'svelte'
	import { logAndAlert } from 'utility/error-handler'
	import { makeSvelteAttachmentFns } from 'utility/attachment-helper'
	import { v4 as uuid } from '@lukeed/uuid'

	export let asr: SvelteAsr
	export let inventoryTypeList: Array<InventoryType> = []
	export let interchangeList: Array<InterchangeWithPricing> = []
	export let isLoading = false
	export let exactNotes = ''
	export let inexactNotes = ''
	export let isPicking = false
	export let didSearch = false
	export let manufacturerOrMake: string = ''
	export let model: string = ''
	export let year: string = ''

	export let fileList: ComponentProps<Attachments>['fileList'] = []

	// might not need this
	let sortedInterchange: Array<InterchangeWithPricing> = []
	let sortedInterchangeSummary: Array<InterchangeWithPricing> = []

	let selectedInterchange: InterchangeWithPricing | null = null
	let interchangeModal: {
		shown: boolean
		selectedInterchange: InterchangeWithPricing | null
	} = {
		shown: false,
		selectedInterchange: null,
	}
	let table: Table<(typeof displayInterchange)[number]> | undefined
	let reportJobModal: ReportJobModal
	// hmm, not sure what this should be initialized as
	let isExactSearch = false
	// this can probably be narrowed once If igure out what it is
	let currentSearchType = ''
	let exactNoteLoading = false
	let inexactNoteLoading = false

	$: displayInterchange = interchangeList.map(interchange => {
		const { firstYear, lastYear } = interchange
		return {
			...interchange,
			yearRange: firstYear !== lastYear ? `${firstYear} - ${lastYear}` : firstYear,
		}
	})

	$: showSide = interchangeList.some(({ side }) => side)
	$: table?.setColumnVisibility(['side'], showSide)
	// TODO: Ractive version gets this from the modal, but why do we have selectedInterchange in the modal and at the top level?
	$: selectedInterchangeId = interchangeModal.selectedInterchange?.interchangeId ?? null
	$: inexactInterchangeNumber = interchangeModal.selectedInterchange?.interchangeNumber ? stripNonNumeric(interchangeModal.selectedInterchange.interchangeNumber) : ''
	$: exactInterchangeNumber = interchangeModal.selectedInterchange?.interchangeNumber === inexactInterchangeNumber ? 'N/A' : interchangeModal.selectedInterchange?.interchangeNumber
	$: fileAssociation = buildFileAssociation(interchangeModal.selectedInterchange?.interchangeNumber, interchangeModal.selectedInterchange?.inventoryTypeId)

	const mediator = getContext<Mediator>('mediator')
	const attachmentFns = makeSvelteAttachmentFns(mediator)
	const dispatch = createEventDispatcher<{
		interchangeSelection: { interchange: InterchangeWithPricing }
	}>()
	const columns: Array<Column> = [
		{ property: 'interchangeNumber', name: 'Interchange #', sortType: 'ALPHA_NUM' },
		{ property: 'make', name: 'Make / Manuf.' },
		{ property: 'model', name: 'Model' },
		{ property: 'yearRange', name: 'Year' },
		{ property: 'option', name: 'Option' },
		{ property: 'side', name: 'Side' },
		{ property: 'listPrice', name: 'List', align: 'right', numeric: true },
		{ property: 'retailPrice', name: 'Retail', align: 'right', numeric: true },
		{ property: 'core', name: 'Core', align: 'right', numeric: true },
		{ property: 'quantityOnHand', name: 'Qty on Hand/Available', align: 'right', numeric: true },
		{ property: 'salesAmount', name: 'Total Sales', align: 'right', numeric: true },
		{ property: 'quantitySold', name: 'Qty Sold', align: 'right', numeric: true },
	]

	if (!inventoryTypeList.length) {
		mediator
			.call('emitToServer', 'load inventory type list', {})
			.then(list => (inventoryTypeList = list as Array<InventoryType>))
			.catch(err => logAndAlert(err, mediator, 'Error loading inventory type list'))
	}

	function buildFileAssociation(interchangeNumber: string | undefined, inventoryTypeId: number | undefined): FileAssociation<'INTERCHANGE'> | null {
		if (interchangeNumber && inventoryTypeId) {
			return {
				type: 'INTERCHANGE',
				key: {
					interchangeNumber,
					inventoryTypeId,
				},
			}
		}
		return null
	}

	function onInterchangeRowClick(event: MouseEvent, inventoryTypeId: number, interchangeNumber: string) {
		event.stopPropagation()
		asr.go('app.part-search.results', { inventoryTypeId, interchangeNumber })
	}

	async function loadFileInfo(interchange: InterchangeWithPricing) {
		fileList = []
		const res = (await mediator.call('emitToServer', 'load file info list', {
			association: {
				type: 'INTERCHANGE',
				key: {
					interchangeNumber: interchange.interchangeNumber,
					inventoryTypeId: interchange.inventoryTypeId,
				},
			},
		})) as Array<FileItem>
		fileList = res.map(file => ({
			...file,
			uuid: uuid(),
		}))
	}

	function printInfo(interchangeNumber: string, inventoryTypeId: number) {
		let interchangeNumberWhere = ''

		if (isExactSearch) {
			interchangeNumberWhere = `= '${interchangeNumber}'`
		} else {
			const [interchangeNumberDigits] = interchangeNumber.match(/\d+/g) || [null]
			interchangeNumberWhere = `LIKE '${interchangeNumberDigits}%'`
		}

		const where = `typenum = ${inventoryTypeId} AND interchangenumber ${interchangeNumberWhere}`

		reportJobModal.open({
			type: 'Interchange Report',
			parameters: {
				make: manufacturerOrMake,
				model,
				year,
				intlookup: where,
			},
		})
	}

	export async function search(options: Parameters<typeof interchangeLookup>[1]) {
		currentSearchType = options.searchType
		isLoading = true
		selectedInterchange = null
		didSearch = true

		if (options.interchangeNumber && !options.isExact) {
			options.interchangeNumber = stripNonNumeric(options.interchangeNumber)
		}

		try {
			interchangeList = await interchangeLookup(mediator, options)

			return interchangeList
		} finally {
			isLoading = false
		}
	}

	export function show(list: Array<InterchangeWithPricing>, type: string) {
		interchangeList = list
		currentSearchType = type
	}

	async function showInterchangeModal(interchange: InterchangeWithPricing) {
		interchangeModal = {
			selectedInterchange: interchange,
			shown: true,
			// exactNotes: '',
			// inexactNotes: '',
		}
		void loadFileInfo(interchange)
		exactNotes = ''
		inexactNotes = ''

		const inventoryTypeId = interchange.inventoryTypeId
		const interchangeNumber = interchange.interchangeNumber
		const nonNumbericInterchangeNumber = stripNonNumeric(interchangeNumber)

		if (nonNumbericInterchangeNumber === interchangeNumber) {
			exactNotes = ''
		} else {
			exactNoteLoading = true
			try {
				exactNotes = (await mediator.call('emitToServer', 'load interchange notes', { inventoryTypeId, interchangeNumber: nonNumbericInterchangeNumber })) as string
			} catch (err) {
				logAndAlert(err, mediator, 'Error loading exact interchange notes')
			} finally {
				exactNoteLoading = false
			}
		}

		inexactNoteLoading = true
		try {
			inexactNotes = (await mediator.call('emitToServer', 'load interchange notes', { inventoryTypeId, interchangeNumber })) as string
		} catch (err) {
			logAndAlert(err, mediator, 'Error loading inexact interchange notes')
		} finally {
			inexactNoteLoading = false
		}
	}

	function closeInterchangeModal() {
		interchangeModal = {
			shown: false,
			selectedInterchange: null,
		}
	}

	export async function clear() {
		inventoryTypeList = []
		interchangeList = []
		isLoading = false
		sortedInterchange = []
		selectedInterchange = null
		exactNotes = ''
		inexactNotes = ''
		sortedInterchangeSummary = []
		didSearch = false
		isPicking = false
		interchangeModal = {
			shown: false,
			selectedInterchange: null,
		}
		fileList = []
	}

	function stripNonNumeric(value: string) {
		// eslint-disable-next-line no-useless-escape
		const res = value.match(/(?:\d*\-)?\d+/g)
		return res ? res.join('') : ''
	}
</script>

{#if isLoading}
	<h1 class="text-center">
		<i class="fa fas fa-spin fa-spinner"></i>
	</h1>
{:else}
	<div class="card">
		<div class="card-body">
			{#if displayInterchange.length > 0}
				{#if !isPicking && currentSearchType !== 'GROUP'}
					Click a row to see interchangeability
				{/if}
				<Table
					responsive
					columnHidingEnabled
					rowSelectionEnabled
					rows={displayInterchange}
					perPageCount={15}
					{columns}
					bind:this={table}
					let:row
				>
					<tr
						class="cursor-pointer"
						class:table-primary={row.interchangeId === selectedInterchangeId}
						on:click={() => dispatch('interchangeSelection', { interchange: row })}
					>
						<Td
							stopPropagation
							property="interchangeNumber"
						>
							<div class="d-flex justify-content-between">
								<span>{row.interchangeNumber}</span>
								<Button
									outline
									color="info"
									size="sm"
									iconClass="sticky-note"
									on:click={event => {
										// idk why but stop prop on the td wasn't working
										event.stopPropagation()
										showInterchangeModal(row)
									}}>Notes</Button
								>
							</div>
						</Td>
						<Td property="make">{row.make}</Td>
						<Td property="model">{row.model}</Td>
						<Td property="yearRange">{row.yearRange}</Td>
						<Td property="side">{row.side}</Td>
						<Td property="option">{row.option}</Td>
						<Td property="listPrice">{row.listPrice ? currencyFormat(row.listPrice) : ''}</Td>
						<Td property="retailPrice">{currencyFormat(row.retailPrice)}</Td>
						<Td property="core">{currencyFormat(row.core)}</Td>
						<Td property="quantityOnHand">
							{row.quantityOnHand}/{row.quantityAvailable}<br />
							{#if !isPicking && (row.quantityOnHand || row.quantityAvailable)}
								<Button
									outline
									size="sm"
									iconClass="search"
									on:click={event => onInterchangeRowClick(event, row.inventoryTypeId, row.interchangeNumber)}>Show Inventory</Button
								>
							{/if}
						</Td>
						<Td property="salesAmount">{currencyFormat(row.salesAmount)}</Td>
						<Td property="quantitySold">{row.quantitySold}</Td>
					</tr>
				</Table>
			{:else if didSearch}
				<h4>No Results</h4>
			{/if}
		</div>
	</div>
{/if}

<Modal
	bind:show={interchangeModal.shown}
	title={interchangeModal.selectedInterchange?.interchangeNumber ?? ''}
	modalSize="lg"
	confirmButtonText="Done"
	cancelShown={false}
	on:confirm={closeInterchangeModal}
	on:close={() => (interchangeModal.shown = false)}
>
	<div class="form-row">
		<div class="col-md-6">
			<Textarea
				readonly
				label="Exact Notes ({exactInterchangeNumber})"
				isLoading={exactNoteLoading}
				rows="10"
				value={exactNotes}
			/>
		</div>
		<div class="col-md-6">
			<Textarea
				readonly
				label="Inexact Notes ({inexactInterchangeNumber})"
				isLoading={inexactNoteLoading}
				rows="10"
				value={inexactNotes}
			/>
		</div>
	</div>

	<Button
		outline
		size="sm"
		iconClass="print"
		disabled={!interchangeModal.selectedInterchange?.interchangeNumber || !interchangeModal.selectedInterchange?.inventoryTypeId}
		colorGreyDisabled={false}
		on:click={() => {
			if (interchangeModal.selectedInterchange?.interchangeNumber && interchangeModal.selectedInterchange?.inventoryTypeId) {
				printInfo(interchangeModal.selectedInterchange?.interchangeNumber, interchangeModal.selectedInterchange?.inventoryTypeId)
			}
		}}>Print Info</Button
	>

	<hr />

	<Attachments
		{fileList}
		on:filesAdded={event => fileAssociation && attachmentFns.onFilesAdded(event.detail, fileAssociation)}
		on:filesDeleted={event => fileAssociation && attachmentFns.onFilesDeleted(event.detail, fileAssociation)}
		on:publicChange={event => fileAssociation && attachmentFns.onPublicChange(event.detail, fileAssociation)}
		on:rankChange={event => fileAssociation && attachmentFns.onRankChange(event.detail, fileAssociation)}
	/>
</Modal>

<ReportJobModal bind:this={reportJobModal}></ReportJobModal>
