//I should have used a Map here. Sorry.
//TODO: fix

export default [
	{
		code: 'DELIVERY',
		name: 'Delivery',
		documentId: 'quoteId',
		documentLineId: 'quoteLineId',
		viewPermission: 'PM_DELIVERIES_VIEW',
		addEditPermission: 'PM_DELIVERIES_ADD_EDIT',
	},
	{
		code: 'QUOTE',
		name: 'Quote',
		documentId: 'quoteId',
		documentLineId: 'quoteLineId',
		viewPermission: 'PM_QUOTES_VIEW',
		addEditPermission: 'PM_QUOTES_ADD',
	},
	{
		code: 'WORK_ORDER',
		name: 'Work Order',
		documentId: 'quoteId',
		documentLineId: 'quoteLineId',
		viewPermission: 'PM_WORK_ORDERS_VIEW',
		addEditPermission: 'PM_WORK_ORDERS_ADD_EDIT',
	},
	{
		code: 'INVOICE',
		name: 'Invoice',
		documentId: 'saleId',
		documentLineId: 'saleLineId',
		viewPermission: 'PM_INVOICE_VIEW',
		addEditPermission: 'PM_INVOICE_ADD',
	},
	{
		code: 'BUILD_ORDER',
		name: 'Build Order',
		documentId: 'quoteId',
		documentLineId: 'quoteLineId',
		viewPermission: 'PM_BUILD_ORDERS_VIEW',
		addEditPermission: 'PM_BUILD_ORDERS_ADD_EDIT',
		isHiddenFromListByDefault: true,
	},
	{
		code: 'INTERNET_ORDER',
		name: 'Internet Order',
		documentId: 'quoteId',
		documentLineId: 'quoteLineId',
		viewPermission: 'PM_INTERNET_ORDERS_VIEW',
	},
]
