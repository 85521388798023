import template from './type.html'
import fuzzy from 'fuzzysort'
import klona from 'klona'
import { booleanToYesNo as yesNo } from '@isoftdata/utility-string'
import pProps from 'p-props'

//Ractive components
import makeCurrencyInput from '@isoftdata/currency-input'
import makeNavTabBar from '@isoftdata/nav-tab-bar-component'
import makeCheckbox from '@isoftdata/checkbox'
import makeButton from '@isoftdata/button'
import makeSelect from '@isoftdata/select'
import makeModal from '@isoftdata/modal'
import makeTable from '@isoftdata/table'
import makeInput from '@isoftdata/input'

export default function({ mediator, stateRouter, logAndAlert }) {
	async function loadHtpTypes() {
		const types = await mediator.call('emitToServer', 'htp api', {
			endpoint: '/inventory/available/part_types',
			params: { all_results: 1 },
		})

		return types.map(type => {
			return { ...type, partTypeId: parseInt(type.partTypeId, 10) }
		})
	}

	const stateName = 'app.configuration.inventory.type'

	stateRouter.addState({
		name: stateName,
		route: 'type',
		querystringParameters: [ 'inventoryTypeId' ],
		template: {
			template,
			components: {
				itTable: makeTable({ stickyHeader: true }),
				itInput: makeInput({ twoway: true, lazy: false }),
				itSelect: makeSelect({ twoway: true, lazy: false }),
				itModal: makeModal(),
				itCheckbox: makeCheckbox(),
				itCurrencyInput: makeCurrencyInput({ twoway: true }),
				itButton: makeButton(),
				navTabBar: makeNavTabBar(stateRouter),
			},
			computed: {
				modalTypeSetIsValid() {
					const { typeSetId, inventoryTypeId } = this.get('inventoryTypeItem')
					if (typeSetId) {
						return typeSetId === inventoryTypeId || this.get('inventoryTypes').find(type => type.inventoryTypeId === typeSetId)
					} else {
						return false
					}
				},
				modalInventoryRecordIsValid() {
					const { name } = this.get('inventoryTypeItem')
					const typeSetIdIsValid = this.get('modalTypeSetIsValid')

					return name && typeSetIdIsValid
				},
				selectedInventoryType() {
					const selectedInventoryTypeId = this.get('selectedInventoryTypeId')

					return this.get('inventoryTypes')
						.find(({ inventoryTypeId }) => inventoryTypeId === selectedInventoryTypeId) || {}
				},
				childStates() {
					const inventoryType = this.get('selectedInventoryType')
					let stateParameters = {}

					if (inventoryType && inventoryType.inventoryTypeId) {
						stateParameters = {
							inventoryTypeId: inventoryType.inventoryTypeId,
							typeSetId: inventoryType.inventoryTypeId,
						}
					}

					return [
						{
							title: 'Manufacturers & Models',
							stateName: 'app.configuration.inventory.type.model',
							stateParameters,
							disabled: !(inventoryType && inventoryType.usesManufacturerModel),
						},
						{
							title: 'Categories',
							stateName: 'app.configuration.inventory.type.category',
							stateParameters,
						},
						{
							title: 'Questions',
							stateName: 'app.configuration.inventory.type.question',
							stateParameters,
						},
					]
				},
				hasSelectedInventoryType() {
					const type = this.get('selectedInventoryType')
					return !!(type && type.inventoryTypeId)
				},
				displayInventoryTypes() {
					const htpInventoryTypes = this.get('htpInventoryTypes')

					const res = this.get('inventoryTypes')
						.map((inventoryType, sourceIndex) => {
							//this will cover explicit mapping(partListAuthorityId is set) or implicit mapping
							const htpType = htpInventoryTypes.find(htpType => {
								return htpType.partTypeId === inventoryType.partListAuthorityId ||
									htpType.partTypeId === inventoryType.inventoryTypeId
							})

							return {
								...inventoryType,
								isValid: yesNo(inventoryType.isValid),
								usesManufacturerModel: yesNo(inventoryType.usesManufacturerModel),
								willShowOnline: yesNo(inventoryType.willShowOnline),
								isVehicleUnit: yesNo(inventoryType.isVehicleUnit),
								sourceIndex,
								htpType,
								htpTypeNameSort: htpType ? htpType.partTypeName : 'zz',
								sourceInventoryType: inventoryType,
							}
						})

					return res
				},
				suggestedHTPTypes() {
					const inventoryTypes = this.get('inventoryTypes')

					return this.get('htpInventoryTypes').reduce((acc, htpType) => {
						const hasExistingMapping = inventoryTypes.find(type => {
							return (type.inventoryTypeId === htpType.partTypeId ||
								type.partListAuthorityId === htpType.partTypeId)
						})

						return hasExistingMapping ? acc : acc.concat(htpType)
					}, []).map(htpType => {
						return { ...htpType, displayName: `${htpType.partTypeName} - ${htpType.partTypeId}` }
					})

					//this.get('inventoryTypeItem.name')
				},
				inventoryTypeDialogSuggestedHTPTypes() {
					let { name, inventoryTypeId } = this.get('inventoryTypeItem')
					const htpInventoryTypes = this.get('htpInventoryTypes')/*.map(type => {
						return {
							partTypeName: type.partTypeName.replace(/[^a-zA-Z ]/g, ''),
							partTypeId: type.partTypeId,
						}
					})*/

					let specificSuggestions = []

					const nameParts = name.split(' ')
					name = nameParts.length > 0 ? nameParts[0] : name

					if (name) {
						specificSuggestions = fuzzy.go(name, htpInventoryTypes, {
							key: 'partTypeName',
							limit: 10,
						}).map(v => v.obj)
					}

					const exactMatch = htpInventoryTypes
						.find(type => type.partTypeId === inventoryTypeId)

					if (exactMatch) {
						specificSuggestions = [ exactMatch ]
					}

					if (specificSuggestions.length < 5) {
						specificSuggestions = [
							...specificSuggestions,
							...this.get('suggestedHTPTypes').slice(0, 4),
						]
					}

					let miscParts = []

					miscParts = htpInventoryTypes.find(type => type.partTypeId === 9999)

					if (miscParts) {
						specificSuggestions = [
							...specificSuggestions,
							...[ miscParts ],
						]
					}

					return specificSuggestions
				},
				inventoryTypeModalTitle() {
					const ractive = this

					const inventoryTypeName = ractive.get('inventoryTypeItem.name')
					const inventoryTypeId = ractive.get('inventoryTypeItem.inventoryTypeId')

					if (inventoryTypeName && inventoryTypeId) {
						return `${inventoryTypeName} - ${inventoryTypeId}`
					}
					return 'Inventory Type'
				},
				newInventoryType() {
					const inventoryTypeIds = this.get('inventoryTypes')
						.map(inventoryType => inventoryType.inventoryTypeId)

					const newInventoryTypeId = Math.max.apply(Math, inventoryTypeIds) + 1

					return {
						inventoryTypeId: newInventoryTypeId,
						typeSetId: null,
						name: '',
						more1: '',
						more2: '',
						more3: '',
						more4: '',
						coreAmount: 0,
						ratio: 0,
						usesManufacturerModel: true,
						willShowOnline: true,
						partListAuthorityId: null,
						isValid: true,
						isVehicleUnit: false,
					}
				},
				displaySuggestedTypes() {
					const selectedSuggestedTypes = this.get('selectedSuggestedTypes')

					return this.get('suggestedTypes').map(type => {
						return { ...type, isSelected: selectedSuggestedTypes
							.find(selectedType => selectedType.partTypeId === type.partTypeId) }
					})
				},
			},
			typeSetIdInputFocused() {
				if (!this.get('inventoryTypeItem.typeSetId')) {
					this.set('inventoryTypeItem.typeSetId', this.get('inventoryTypeItem.inventoryTypeId'))
				}
			},
			viewSuggestPartTypes() {
				const inventoryTypes = this.get('inventoryTypes')

				const suggestedTypes = this.get('htpInventoryTypes').filter(htpType => {
					return !inventoryTypes.find(type => type.inventoryTypeId === htpType.partTypeId)
				})

				this.set({
					suggestedTypesModalShown: true,
					suggestedTypes,
				})
			},
			toggleSelectedSuggestion(clickedPartType) {
				const typeIndex = this.get('selectedSuggestedTypes')
					.findIndex(type => type.partTypeId === clickedPartType.partTypeId)

				if (typeIndex > -1) {
					this.splice('selectedSuggestedTypes', typeIndex, 1)
				} else {
					this.push('selectedSuggestedTypes', clickedPartType)
				}
			},
			toggleSelectAllSuggestedTypes() {
				const selectedSuggestedTypes = this.get('selectedSuggestedTypes')
				const suggestedTypes = this.get('suggestedTypes')

				if (selectedSuggestedTypes.length === suggestedTypes.length) {
					this.set({ selectedSuggestedTypes: [] })
				} else {
					this.set({ selectedSuggestedTypes: klona(suggestedTypes) })
				}
			},
			addSelectedSuggestedTypes(selectedSuggestedTypes) {
				const typesToAdd = selectedSuggestedTypes.map(type => {
					return { ...this.get('newInventoryType'), inventoryTypeId: type.partTypeId,
						typeSetId: type.partTypeId,
						name: type.partTypeName }
				})

				typesToAdd.forEach(type => {
					this.saveInventoryType(type)
				})

				this.set({ suggestedTypesModalShown: false })
			},
			editInventoryType(inventoryType) {
				this.set({
					inventoryTypeItem: klona(inventoryType || this.get('newInventoryType')),
					inventoryTypeModalShown: true,
					currentInventoryTypeIsExisting: !!inventoryType,
				}).then(() => this.find('#partTypeNameInput').select())
			},
			inventoryTypeClicked(inventoryType) {
				const { name: currentState } = stateRouter.getActiveState()

				stateRouter.go(currentState === stateName ? `${stateName}.model` : null, {
					inventoryTypeId: inventoryType.inventoryTypeId,
					typeSetId: inventoryType.typeSetId,
				})
			},
			async saveInventoryType(inventoryType) {
				const ractive = this

				if (ractive.get('inventoryTypes').find(({ name, inventoryTypeId }) => name === inventoryType.name && inventoryTypeId !== inventoryType.inventoryTypeId)) {
					return alert(`Inventory Type "${inventoryType.name}" already exists. Please choose a unique name.`)
				}

				ractive.set({ inventoryTypeIsSaving: true })

				try {
					await mediator.call('emitToServer', 'save inventory type', { inventoryType })

					ractive.upsert('inventoryTypes', 'inventoryTypeId', inventoryType)
					ractive.set({ inventoryTypeModalShown: false })
				} catch (err) {
					logAndAlert(err, mediator, 'Error saving inventory type')
				} finally {
					ractive.set({ inventoryTypeIsSaving: false })
				}
			},
			setPartListAuthorityId(partListAuthorityId) {
				this.set('inventoryTypeItem.partListAuthorityId', partListAuthorityId)
			},
		},
		async resolve(data, { inventoryTypeId }) {
			const inventoryTypes = await mediator.call('emitToServer', 'load inventory type list', { isValid: null })

			return await pProps({
				suggestedTypes: [],
				selectedSuggestedTypes: [],
				inventoryTypes,
				htpInventoryTypes: loadHtpTypes(),
				selectedInventoryTypeId: parseInt(inventoryTypeId, 10),
				columns: [
					{ property: 'isValid', name: 'Active' },
					{ property: 'inventoryTypeId', name: 'Type #', align: 'right' },
					{ property: 'typeSetId', name: 'Set #', align: 'right' },
					{ property: 'name', name: 'Name' },
					{ property: 'htpTypeNameSort', name: 'HTP Name', endSort: [ '' ] },
					{ property: 'usesManufacturerModel', name: 'Uses Man/Mod' },
					{ property: 'more1', name: 'Custom Field 1' },
					{ property: 'more2', name: 'Custom Field 2' },
					{ property: 'more3', name: 'Custom Field 3' },
					{ property: 'more4', name: 'Custom Field 4' },
					{ property: 'ratio', name: 'Vehicle Cost Ratio', align: 'right' },
					{ property: 'isVehicleUnit', name: 'Vehicle Unit' },
					{ property: 'willShowOnline', name: 'Show Online' },
				],
			})
		},
		activate({ domApi: ractive }) {
			const inventoryType = ractive.get('selectedInventoryType')
			if (inventoryType && inventoryType.inventoryTypeId) {
				ractive.find(`#inventory_type_id_${inventoryType.inventoryTypeId}`)?.scrollIntoView({
					block: 'center',
					inline: 'center',
					behavior: 'smooth',
				})
				/* ractive.findComponent('itTable').setPageVisibleByItemId({
					id: inventoryType.inventoryTypeId,
					keyName: 'inventoryTypeId',
				}) */
			}
		},
	})
}
