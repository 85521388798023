<script lang="ts">
	import type { Inventory } from 'server/db/inventory-db'
	import type { InventoryType } from 'server/db/inventory-type-db'
	import type { Mediator, SvelteAsr } from 'types/common'
	import type { InterchangeWithPricing } from 'server/db/interchange-db'
	import type { Status } from 'server/db/status-db'
	import type { ConditionalKeys } from 'type-fest'

	import PartTypeManModCat from 'components/PartTypeManModCat.svelte'
	import StatusSelect from 'components/StatusSelect.svelte'
	import PartPricingModal from 'components/PartPricingModal.svelte'
	import StoreSelect from 'components/StoreSelect.svelte'
	import InterchangeSelector from 'components/InterchangeSelector.svelte'
	import CustomFields from './CustomFields.svelte'

	import Modal from '@isoftdata/svelte-modal'
	import Table from '@isoftdata/svelte-table'
	import CurrencyInput from '@isoftdata/svelte-currency-input'
	import Input from '@isoftdata/svelte-input'
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import Textarea from '@isoftdata/svelte-textarea'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import Checkbox from '@isoftdata/svelte-checkbox'

	import { getInventoryTypeNameFromId } from 'shared/inventory-type-helper'
	import { format as currencyFormat } from '@isoftdata/utility-currency'
	import { getContext, onMount, tick, type ComponentEvents } from 'svelte'
	import { logAndAlert } from 'utility/error-handler'

	interface PartWithVehicle extends Inventory {
		vehicleId: number | null
		vin: string
		make: string
		model: string
		year: number
		quantityCommitted: number
	}

	// todo get from db handler
	type Vehicle = {
		vehicleId: number
		vin: string
		make: string
		model: string
		year: number
		trackingNumber: string
	}

	export let asr: SvelteAsr
	export let isTruckYard: boolean
	export let allowManualTagNumberEntry: boolean
	export let inventoryTypes: Array<InventoryType>
	export let part: PartWithVehicle
	export let vehicleMakes: Array<{ make: string }>
	export let partConditions: Array<string>
	export let partLocations: Array<string>
	export let saleClassList: Array<{
		code: string
		description: string
		name: string
		saleClassId: number
		webSaleClass: string
	}>
	export let stores: Array<{ storeId: number; name: string }>
	export let frequentlyUsedInventoryTypes: Array<InventoryType>
	export let canEditFinancial: boolean
	export let canEditPart: boolean
	export let canViewCost: boolean
	export let canEditCost: boolean
	export let statusList: Array<Status>
	export let vehicle: Vehicle | null
	export let parentPart: Inventory | null
	export let showCreatePartModal: boolean
	export let showStorePicker: boolean
	export let currentStore: any
	export let inventoryType: InventoryType | null

	let vehicleModels: Array<{
		model: string
	}> = []
	let vehicleIsLoading = false
	let appraisalLoading = false
	let modelLoading = false
	let yearLoading = false
	let vehicleDidJustChange = false
	let parentPartTagNumberDisambigList: Array<Inventory> = []
	let lookupTrackingNumber = ''
	let trackingLoading = false
	let partModelId: number | null = null
	let htpAppraised: {
		value: number
		min: number
		max: number
		itemsaffected: number
	} | null = null
	// check the checkbox if we have a sale class code that isn't "NONE"
	let ecommerce = !!part.saleClassCode && part.saleClassCode.toUpperCase() !== 'NONE'
	let showTrackingNumberDisambiguationModal = false
	let trackingNumberDisambigList: Array<Vehicle> = []
	let showParentPartTagNumberDisambiguationModal = false
	let lookupParentPartTagNumber = ''

	let partPricingModal: PartPricingModal
	let interchangeSelector: InterchangeSelector

	const mediator = getContext<Mediator>('mediator')
	$: inventoryId = part?.inventoryId ?? null
	$: displayAppraisalTooltip = htpAppraised
		? `Min: ${currencyFormat(htpAppraised.min)}
Max: ${currencyFormat(htpAppraised.max)}
Total Items: ${htpAppraised.itemsaffected}`
		: ''

	$: inventoryType = part.inventoryTypeId ? inventoryTypes.find(inventoryType => inventoryType.inventoryTypeId === part.inventoryTypeId) ?? null : null
	$: displayParentPartTagNumberDisambigList = parentPartTagNumberDisambigList.map(parentPart => {
		const inventoryTypeName = getInventoryTypeNameFromId(inventoryTypes, parentPart.inventoryTypeId) as string

		return { ...parentPart, inventoryTypeName }
	})
	$: displayParentPart = parentPart
		? {
				...parentPart,
				inventoryTypeName: getInventoryTypeNameFromId(inventoryTypes, parentPart.inventoryTypeId) as string,
			}
		: null

	$: availableQuantity = (typeof part.quantity === 'number' && typeof part.quantityCommitted === 'number' ? part.quantity - part.quantityCommitted : 0) || 0

	$: displaySaleClassList = ecommerce ? saleClassList : saleClassList.filter(saleClass => saleClass.webSaleClass === 'None')

	$: part && savePart()

	//#region Utilities
	function stringPropChange(event: Event, prop: ConditionalKeys<PartWithVehicle, string | (string | null)>) {
		if (event.target instanceof HTMLInputElement || event.target instanceof HTMLSelectElement || event.target instanceof HTMLTextAreaElement) {
			part[prop] = event.target.value
		} else {
			console.error('Failed to update part property', prop)
		}
	}

	function numberPropChange(event: Event, prop: ConditionalKeys<PartWithVehicle, number | (number | null)>) {
		if (event.target instanceof HTMLInputElement) {
			part[prop] = Number(event.target.value)
		} else {
			console.error('Failed to update part property', prop)
		}
	}

	function zeroIfNull(value: number | null) {
		return value ?? 0
	}
	// #endregion

	async function appraise() {
		if (part && part.inventoryId) {
			appraisalLoading = true

			try {
				const appraisedItems = (await mediator.call('emitToServer', 'appraise items', {
					itemsToAppraise: [
						{
							id: part?.inventoryId,
							inventoryTypeId: part?.inventoryTypeId,
							partManufacturer: part?.partManufacturer || '',
							partModel: part?.partModel || '',
							category: part?.category,
							vehicleMake: vehicle?.make || '',
							vehicleModel: vehicle?.model || '',
							year: part?.year || 0,
						},
					],
					originCode: 'ORIGIN_HTP_LIVE',
				})) as Array<any>

				if (appraisedItems?.[0]) {
					htpAppraised = appraisedItems[0]
				}
			} catch (err) {
				logAndAlert(err, mediator, 'Failed to appraise items')
				htpAppraised = null
			} finally {
				appraisalLoading = false
			}
		}
	}

	// #region Vehicle / Tracking Number Lookup
	function cancelVehicleDisambiguation() {
		vehicleIsLoading = false
		showTrackingNumberDisambiguationModal = false
		trackingNumberDisambigList = []
		lookupTrackingNumber = ''
	}

	async function setVehicle(newVehicle: any) {
		part = {
			...part,
			vehicleId: newVehicle.vehicleId,
			vin: newVehicle.vin,
			make: newVehicle.make,
			model: newVehicle.model,
			year: newVehicle.year,
		}
		vehicleIsLoading = false
		vehicle = newVehicle
		showTrackingNumberDisambiguationModal = false
		trackingNumberDisambigList = []
		await tick()
		tryInterchangeLookup()
	}

	async function vehicleTrackingNumberLookup(lookupTrackingNumber: string) {
		async function trackingNumberNotFound(clearVehicle = false) {
			vehicleIsLoading = false
			showTrackingNumberDisambiguationModal = false
			trackingNumberDisambigList = []

			document.getElementById('TrackingNumber')?.focus()

			if (clearVehicle) {
				vehicle = null
				part.vehicleId = null
			}
			mediator.call('showMessage', {
				heading: 'Vehicle Not Found',
				message: `No vehicle found with tracking number "${lookupTrackingNumber}".`,
				time: 10000,
				type: 'info',
			})
		}

		if (!lookupTrackingNumber.trim()) {
			await trackingNumberNotFound(true)
		} else {
			const vehicles = (await mediator.call('emitToServer', 'tracking number lookup', {
				searchString: lookupTrackingNumber,
				trackingNumberExact: true,
			})) as Array<any>

			if (vehicles?.length === 1) {
				const vehicle = vehicles[0]

				setVehicle(vehicle)
			} else if (vehicles?.length > 1) {
				vehicleIsLoading = false
				showTrackingNumberDisambiguationModal = true
				trackingNumberDisambigList = vehicles
			} else {
				await trackingNumberNotFound()
			}
		}
	}
	// #endregion
	// #region Assembly / Parent Part Lookup
	function cancelAssemblyDisambiguation() {
		showParentPartTagNumberDisambiguationModal = false
		parentPartTagNumberDisambigList = []
		lookupParentPartTagNumber = ''
	}

	function setAssembly(assembly: Inventory) {
		showParentPartTagNumberDisambiguationModal = false
		parentPartTagNumberDisambigList = []
		part.parentInventoryId = assembly.inventoryId
		parentPart = assembly
	}

	async function assemblyTagNumberLookup(lookupParentPartTagNum: string) {
		async function parentPartTagNumberNotFound(clearAssembly?: boolean) {
			showParentPartTagNumberDisambiguationModal = false
			parentPartTagNumberDisambigList = []
			await tick()
			document.getElementById('assemblyNumber')?.focus()

			if (clearAssembly) {
				parentPart = null
				part.parentInventoryId = -1
			}

			mediator.call('showMessage', {
				heading: 'Assembly Not Found',
				message: `No part found with tag number "${lookupParentPartTagNum}".`,
				time: 10000,
				type: 'info',
			})
		}

		if (!lookupParentPartTagNum.trim()) {
			await parentPartTagNumberNotFound(true)
		} else {
			let disambigParts = (await mediator.call('emitToServer', 'load inventory', { tagNumber: lookupParentPartTagNum })) as Array<Inventory>
			if (part?.inventoryId && disambigParts) {
				disambigParts = disambigParts.filter(disambigPart => disambigPart.inventoryId !== part.inventoryId)
			}

			console.log(disambigParts)

			if (disambigParts && disambigParts.length === 1) {
				setAssembly(disambigParts[0])
			} else if (disambigParts && disambigParts.length > 1) {
				showParentPartTagNumberDisambiguationModal = true
				parentPartTagNumberDisambigList = disambigParts
				lookupParentPartTagNumber = lookupParentPartTagNum
			} else {
				await parentPartTagNumberNotFound()
			}
		}
	}
	// #endregion

	function tryInterchangeLookup() {
		if (!part.interchangeNumber) {
			console.log('trying interchange lookup')
			interchangeSelector.tryInterchangeLookup()
		} else {
			console.log('no interchange number')
		}
	}

	async function onCreatePart(part: Inventory) {
		if (part && part.inventoryTypeId) {
			try {
				const savedPart = (await mediator.call('emitToServer', 'save inventory item', { item: part })) as Inventory
				asr.go('app.redirect', {
					state: 'app.part',
					inventoryId: savedPart.inventoryId,
					storeId: savedPart.storeId,
				})
			} catch (err) {
				logAndAlert(err, mediator, 'Error creating part', part)
			}
		}
	}

	// ractive.observe('part',
	async function savePart() {
		console.log('saving part', part)
		if (part && part.inventoryId) {
			try {
				const savedPart = await mediator.call('emitToServer', 'save inventory item', { item: part })
				// @ts-expect-error
				mediator.call('partSave', {
					stateThatSaved: 'app.part.basic',
					data: savedPart,
				})
			} catch (err) {
				logAndAlert(err, mediator, 'Error saving part')
			}
		}
	}

	// ractive.observe('part.make', async vehicleMake => {
	async function loadVehicleModels(vehicleMake: string) {
		if (vehicleMake) {
			vehicleModels = (await mediator.call('emitToServer', 'load vehicle models', { make: vehicleMake })) as Array<any>
		}
	}

	// ractive.on('back', () => window.history.back())

	function showPricingModal(context: 'CATEGORY' | 'INTERCHANGE', interchangeRecord?: InterchangeWithPricing) {
		if (context === 'INTERCHANGE' && !interchangeRecord) {
			throw new Error('interchangeRecord is required when context is INTERCHANGE')
		}

		if (canEditFinancial) {
			partPricingModal.showIfNeeded({
				context,
				inventoryTypeId: part.inventoryTypeId,
				category: part.category,
				interchangeRecord,
				interchangeNumber: part.interchangeNumber,
				partModelId,
				partPricing: {
					retailPrice: part.retailPrice ?? 0,
					wholesalePrice: part.wholesalePrice ?? 0,
					listPrice: part.listPrice ?? 0,
					core: part.core ?? 0,
				},
			})
		}
	}

	function onInterchangeNumberSelected(interchangeRecord: InterchangeWithPricing) {
		part.interchangeNumber = interchangeRecord.interchangeNumber
		if (interchangeRecord && part.inventoryTypeId) {
			showPricingModal('INTERCHANGE', interchangeRecord)
		}
	}

	function onPartCategoryChange(categoryName: string) {
		console.log('category changed', categoryName, partModelId)
		if (categoryName && partModelId) {
			console.log('showing pricing modal? category')
			showPricingModal('CATEGORY')
		}
	}

	// from part pricing modal
	function onPricingChange(event: ComponentEvents<PartPricingModal>['pricingChange']) {
		const newPricing = event.detail
		part = {
			...part,
			...newPricing,
		}
	}

	onMount(() => {
		appraise()

		if (showCreatePartModal) {
			document.getElementById('createPartInventoryTypeInput')?.focus()
		} else if (part.partManufacturer) {
			document.getElementById('PartManufacturerInput')?.focus()
		}

		if (part.make) {
			loadVehicleModels(part.make)
		}
	})
</script>

<div class="form-row">
	<div class="col-lg-6">
		<div class="card hightlight-card">
			<div class="card-header">
				<div class="d-flex justify-content-between align-items-end">
					<h4 class="m-0">Part</h4>
					{#if currentStore && part && part.inventoryId && isTruckYard}
						<Button
							outline
							size="sm"
							href="https://www.heavytruckparts.net/item/-/-/-/{currentStore.companyCode}/{currentStore.storeId}/{part.inventoryId}"
							target="htp_chromium_popout"
							icon={{
								prefix: 'fak',
								class: 'fa-isoft-bolt',
							}}>View on HeavyTruckParts.net</Button
						>
					{/if}
				</div>
			</div>
			<div class="card-body">
				<div class="form-row">
					<div class="col-md">
						<div class="form-row">
							<div class="col">
								<Input
									id="tagNumber"
									type="text"
									label="Tag #"
									autocomplete="off"
									placeholder="Leave blank to set automatically on save"
									disabled={!canEditPart || !allowManualTagNumberEntry}
									value={part.tagNumber}
									on:change={event => stringPropChange(event, 'tagNumber')}
								/>
							</div>
							{#if showStorePicker}
								<div class="col">
									<StoreSelect
										storeList={stores}
										showAllOption={false}
										parentDivClass="col-md-6"
										disabled={!canEditPart}
										bind:selectedStoreId={part.storeId}
									/>
								</div>
							{/if}
						</div>

						<PartTypeManModCat
							partManufacturerInputId="PartManufacturerInput"
							inventoryTypeDisabled={!canEditPart}
							partManufacturerDisabled={!part.inventoryId || !canEditPart}
							partModelDisabled={!part.inventoryId || !canEditPart}
							categoryDisabled={!part.inventoryId || !canEditPart}
							colSizeClass="col-xl-6"
							bind:inventoryTypeId={part.inventoryTypeId}
							bind:partManufacturer={part.partManufacturer}
							bind:partModel={part.partModel}
							bind:category={part.category}
							bind:partModelId
							on:categoryChange={event => onPartCategoryChange(event.detail.category?.name ?? '')}
							on:inventoryTypeChange={tryInterchangeLookup}
							on:partManufacturerChange={tryInterchangeLookup}
							on:partModelChange={tryInterchangeLookup}
						/>
						<!-- on:inventoryTypeChange={onInventoryTypeChange}
						on:partManufacturerChange={partManufacturerChange}
						on:partModelChange={partModelChange} -->
						<InterchangeSelector
							{asr}
							inventoryTypeId={part.inventoryTypeId}
							partManufacturerName={part.partManufacturer}
							partModelName={part.partModel}
							vehicleYear={part.year}
							vehicleMake={part.make}
							vehicleModel={part.model}
							disabled={!inventoryId || !canEditPart}
							bind:interchangeNumber={part.interchangeNumber}
							bind:this={interchangeSelector}
							on:interchangeNumberSelected={event => onInterchangeNumberSelected(event.detail.interchange)}
						/>
					</div>
					<div class="col-md">
						<div class="form-row">
							<div class="col-md">
								<StatusSelect
									filterStatuses={['@']}
									disabled={!inventoryId || !canEditPart}
									showEmptyOption={false}
									statuses={statusList}
									value={part.status ?? ''}
									on:change={event => {
										if (event.target instanceof HTMLSelectElement) {
											part.status = event.target.value
										}
									}}
								/>
							</div>
							<div class="col-md">
								<Select
									id="side"
									label="Side"
									isLoading={modelLoading}
									disabled={!canEditPart}
									emptyValue=""
									bind:value={part.side}
								>
									<option value="Left">Left</option>
									<option value="Right">Right</option>
								</Select>
							</div>
						</div>
						<div class="form-row">
							<div class="col-xl-6">
								<Autocomplete
									canAddNew
									id="location"
									label="Location"
									disabled={!canEditPart}
									bind:options={partLocations}
									bind:value={part.location}
								/>
							</div>
							<div class="col-xl-6">
								<Autocomplete
									canAddNew
									id="condition"
									label="Condition"
									disabled={!canEditPart}
									bind:options={partConditions}
									bind:value={part.condition}
								/>
							</div>
						</div>
						<div class="form-row">
							<div class="col-xl-6">
								<Input
									id="serial-number"
									label="Serial #"
									disabled={!!part.replenish || !inventoryId || !canEditPart}
									value={part.serialNumber}
									on:change={event => stringPropChange(event, 'serialNumber')}
								/>
							</div>
							<div class="col-xl-6">
								<Input
									id="oem-number"
									label="OEM #"
									disabled={!inventoryId || !canEditPart}
									value={part.oemNumber}
									on:change={event => stringPropChange(event, 'oemNumber')}
								/>
							</div>
						</div>

						<Input
							id="upc"
							label="UPC"
							disabled={!inventoryId || !canEditPart}
							value={part.upc}
							on:change={event => stringPropChange(event, 'upc')}
						/>
					</div>
				</div>
				<div class="card mt-2">
					<h5 class="card-header">Stock Configuration</h5>
					<div class="card-body">
						<div class="form-row">
							<div class="col-sm-12 col-md-6 col-lg-3">
								<div class="form-check p-0">
									<Checkbox
										labelRight="true"
										label="Replenishable"
										disabled={!inventoryId || !canEditPart}
										bind:checked={part.replenish}
									/>
								</div>

								<div class="form-check p-0">
									<Checkbox
										labelRight="true"
										label="Deplete"
										disabled={!inventoryId || !canEditPart}
										bind:checked={part.deplete}
									/>
								</div>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-3">
								<div class="form-check p-0">
									<Checkbox
										labelRight="true"
										label="Taxable"
										disabled={!inventoryId || !canEditPart}
										bind:checked={part.isTaxable}
									/>
								</div>

								<div class="form-check p-0">
									<Checkbox
										labelRight="true"
										label="Show Online"
										disabled={!inventoryId || !canEditPart}
										bind:checked={part.isWorldViewable}
									/>
								</div>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-3">
								<div class="form-check p-0">
									<Checkbox
										labelRight="true"
										label="Needs Tag"
										disabled={!inventoryId || !canEditPart}
										bind:checked={part.tag}
									/>
								</div>

								<div class="form-check p-0">
									<Checkbox
										labelRight="true"
										label="Tag per Quantity"
										disabled={!inventoryId || !canEditPart}
										bind:checked={part.isTagPerQuantity}
									/>
								</div>
							</div>

							<div class="col-sm-12 col-md-6 col-lg-3">
								<div class="form-check p-0">
									<Checkbox
										labelRight="true"
										label="E-commerce"
										disabled={!inventoryId || !canEditPart}
										bind:checked={ecommerce}
										on:change={() => (part.saleClassCode = ecommerce ? '72HR' : displaySaleClassList[0]?.code ?? null)}
									/>
								</div>

								<Select
									label="Ships Within"
									labelClass="pt-0"
									disabled={!inventoryId || !canEditPart || displaySaleClassList.length === 0}
									showEmptyOption={part.saleClassCode === null}
									emptyText="N/A"
									options={displaySaleClassList}
									bind:value={part.saleClassCode}
									on:change={() => {
										const salesClass = saleClassList.find(saleClass => saleClass.code === part.saleClassCode)
										if (salesClass?.webSaleClass.toUpperCase() === 'NONE') {
											ecommerce = false
										}
									}}
									let:option
								>
									<option
										value={option?.code}
										title={option?.description}>{option?.name}</option
									>
								</Select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="col-lg-6 d-flex flex-wrap">
		<div
			class="card hightlight-card mt-4 mt-lg-0 border border-transition"
			class:border-primary={vehicleDidJustChange}
		>
			<h4
				class="card-header d-flex"
				style="min-height: 56px;"
			>
				<span class="mr-auto">Vehicle</span>

				{#if vehicle?.vehicleId}
					<Button
						size="sm"
						iconClass="truck"
						href={asr.makePath('app.vehicle', { vehicleId: vehicle.vehicleId })}
					>
						Open Vehicle
					</Button>
				{/if}
			</h4>
			<div class="card-body">
				<div class="form-row">
					<div class="col-sm-6 col-md-4 col-lg-6 col-xl-4">
						<Input
							selectOnFocus
							id="TrackingNumber"
							placeholder="Load a Vehicle"
							label="Tracking #"
							isLoading={vehicleIsLoading}
							disabled={!inventoryId || !canEditPart}
							value={vehicle?.trackingNumber ?? ''}
							on:change={event => {
								console.log('tracking #', event)
								if (event.target instanceof HTMLInputElement) {
									// vehicle.trackingNumber = event.target.value
									vehicleTrackingNumberLookup(event.target.value)
								}
							}}
						/>
					</div>
					<div class="col-sm-6 col-md-4 col-lg-6 col-xl-4">
						<Input
							label="VIN"
							isLoading={trackingLoading}
							disabled={!!vehicle?.vehicleId || !inventoryId || !canEditPart}
							value={part.vin}
							on:change={event => stringPropChange(event, 'vin')}
						/>
					</div>
					<div class="col-sm-6 col-md-4 col-lg-6 col-xl-4">
						<Input
							id="body-style"
							label="Body Style"
							isLoading={trackingLoading}
							disabled={!!vehicle?.vehicleId || !inventoryId || !canEditPart}
							value={part.bodyStyle}
							on:change={event => stringPropChange(event, 'bodyStyle')}
						/>
					</div>
					<div class="col-sm-6 col-md-4 col-lg-6 col-xl-4">
						<Input
							id="Year"
							type="number"
							label="Year"
							isLoading={yearLoading}
							disabled={!!vehicle?.vehicleId || !inventoryId || !canEditPart}
							value={part.year}
							on:change={event => {
								numberPropChange(event, 'year')
								tryInterchangeLookup()
							}}
						/>
					</div>
					<div class="col-sm-6 col-md-4 col-lg-6 col-xl-4">
						<Select
							id="Make"
							label="Make"
							emptyValue=""
							disabled={!!vehicle?.vehicleId || !inventoryId || !canEditPart}
							options={vehicleMakes}
							bind:value={part.make}
							on:change={() => {
								loadVehicleModels(part.make)
								tryInterchangeLookup()
							}}
							let:option
						>
							<option value={option?.make}>{option?.make}</option>
						</Select>
					</div>
					<div class="col-sm-6 col-md-4 col-lg-6 col-xl-4">
						<Select
							id="Model"
							label="Model"
							emptyValue=""
							isLoading={modelLoading}
							disabled={!!vehicle?.vehicleId || !inventoryId || !canEditPart}
							options={vehicleModels}
							bind:value={part.model}
							on:change={tryInterchangeLookup}
							let:option
						>
							<option value={option?.model}>{option?.model}</option>
						</Select>
					</div>
				</div>
			</div>
		</div>
		<div class="card hightlight-card mt-4 w-100">
			<h4 class="card-header d-flex">
				<span class="mr-auto">Assembly</span>
				{#if displayParentPart && displayParentPart.inventoryId}
					<Button
						size="sm"
						class="float-right"
						iconClass="wrench"
						href={asr.makePath('app.part', { inventoryId: displayParentPart.inventoryId })}
						>Open Part
					</Button>
				{/if}
			</h4>
			<div class="card-body">
				<div class="form-row">
					<div class="col-sm-6 col-md-4 col-lg-6 col-xl-4">
						<Input
							selectOnFocus
							id="assemblyNumber"
							value={displayParentPart ? displayParentPart.tagNumber : ''}
							label="Assembly Tag #"
							placeholder="Load a Parent Part"
							disabled={!inventoryId || !canEditPart}
							on:change={event => {
								if (event.target instanceof HTMLInputElement) {
									assemblyTagNumberLookup(event.target.value)
								}
							}}
						/>
					</div>

					<div class="col-sm-6 col-md-4 col-lg-6 col-xl-4">
						<Input
							readonly
							label="Part Type"
							value={displayParentPart?.inventoryTypeName ?? ''}
						/>
					</div>
					<div class="col-sm-6 col-md-4 col-lg-6 col-xl-4">
						<Input
							readonly
							value={displayParentPart?.partManufacturer ?? ''}
							label="Manufacturer"
						/>
					</div>
					<div class="col-sm-6 col-md-4 col-lg-6 col-xl-4">
						<Input
							readonly
							value={displayParentPart?.partModel ?? ''}
							label="Model"
						/>
					</div>
					<div class="col-sm-6 col-md-4 col-lg-6 col-xl-4">
						<Input
							readonly
							label="Vehicle Make"
							value={displayParentPart?.make ?? ''}
						/>
					</div>
					<div class="col-sm-6 col-md-4 col-lg-6 col-xl-4">
						<Input
							readonly
							label="Vehicle Model"
							value={displayParentPart?.model ?? ''}
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="form-row">
	<div class="col-lg d-flex flex-wrap">
		<div class="card hightlight-card mt-4 w-100">
			<h4 class="card-header d-flex">
				<span class="mr-auto">Pricing</span>

				<!-- 				<Button
					size="sm"
					class="d-none float-right"
					iconClass="usd-circle"
					on:click={() => partPricingModal.showIfNeeded({ forceShow: true })}
				></Button> -->
			</h4>
			<div class="card-body">
				<div class="form-row">
					<div class="col-4">
						<CurrencyInput
							id="retail-price"
							label="Retail"
							name="retailprice"
							disabled={!inventoryId || !canEditFinancial}
							value={(part.retailPrice ?? 0).toString()}
							on:change={event => (part.retailPrice = Number(event.detail.value))}
						/>
					</div>
					<div class="col-4">
						<CurrencyInput
							id="wholesale-price"
							label="Wholesale"
							name="core-charge"
							disabled={!inventoryId || !canEditFinancial}
							value={(part.wholesalePrice ?? 0).toString()}
							on:change={event => (part.wholesalePrice = Number(event.detail.value))}
						/>
					</div>
					<div class="col-4">
						<CurrencyInput
							id="list-price"
							label="List"
							name="list-price"
							disabled={!inventoryId || !canEditFinancial}
							value={(part.listPrice ?? 0).toString()}
							on:change={event => (part.listPrice = Number(event.detail.value))}
						/>
					</div>
					<div class="col-4">
						<CurrencyInput
							id="core"
							label="Core"
							name="core"
							disabled={!inventoryId || !canEditFinancial}
							value={(part.core ?? 0).toString()}
							on:change={event => (part.core = Number(event.detail.value))}
						/>
					</div>
					{#if canViewCost}
						<div class="col-4">
							<CurrencyInput
								id="cost"
								label="Cost"
								name="cost"
								disabled={!inventoryId || !canEditCost}
								value={(part.cost ?? 0).toString()}
								on:change={event => (part.cost = Number(event.detail.value))}
							/>
						</div>
					{/if}
					<div class="col-4">
						<div class="form-group mb-1">
							<Input
								readonly
								label="HTP Appraised"
								class="text-right"
								value={appraisalLoading ? 'Loading...' : currencyFormat(htpAppraised?.value)}
								title={displayAppraisalTooltip}
							>
								<Button
									slot="append"
									iconClass="redo"
									isLoading={appraisalLoading}
									title="Click to refresh HTP appraisal"
									on:click={appraise}
								></Button>
							</Input>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-lg d-flex flex-wrap">
		<CustomFields
			{asr}
			{part}
			{inventoryType}
			on:moreChange={event => (part[event.detail.key] = event.detail.value)}
		></CustomFields>
	</div>
	<div class="col-lg d-flex flex-wrap">
		<div class="card hightlight-card mt-4 w-100">
			<h4 class="card-header">Quantity</h4>
			<div class="card-body">
				<div class="form-row">
					<div class="col-lg-4">
						<Input
							id="quantity"
							inputmode="decimal"
							type="number"
							class={zeroIfNull(part.quantity) > zeroIfNull(part.maximumQuantity) || zeroIfNull(part.quantity) < zeroIfNull(part.minimumQuantity) ? 'border-danger' : ''}
							label="Quantity"
							hint="On Hand"
							disabled={!inventoryId || !canEditPart}
							value={part.quantity}
							on:change={event => numberPropChange(event, 'quantity')}
						></Input>
					</div>
					<div class="col-lg-4">
						<Input
							id="minimum-quantity"
							inputmode="decimal"
							type="number"
							class={zeroIfNull(part.quantity) < zeroIfNull(part.minimumQuantity) ? 'border-danger' : ''}
							label="Minimum"
							disabled={!inventoryId || !canEditPart}
							value={part.minimumQuantity}
							on:change={event => numberPropChange(event, 'minimumQuantity')}
						></Input>
					</div>
					<div class="col-lg-4">
						<Input
							id="maximum-quantity"
							inputmode="decimal"
							type="number"
							class={zeroIfNull(part.quantity) > zeroIfNull(part.maximumQuantity) ? 'border-danger' : ''}
							label="Maximum"
							disabled={!inventoryId || !canEditPart}
							value={part.maximumQuantity}
							on:change={event => numberPropChange(event, 'maximumQuantity')}
						></Input>
					</div>
					<div class="col-lg-6">
						<Input
							readonly
							id="available-quantity"
							label="Available"
							title="Quantity On Hand - Quantity On Hold"
							value={availableQuantity}
						></Input>
					</div>
					<div class="col-lg-6">
						<Input
							readonly
							id="commit"
							value={part.quantityCommitted}
							label="On Hold"
						></Input>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="form-row">
	<div class="col-lg d-flex flex-wrap">
		<div class="card hightlight-card mt-4 w-100">
			<h4 class="card-header">Description</h4>
			<div class="card-body">
				<Textarea
					label="Description"
					labelClass="sr-only"
					for="description"
					rows="6"
					disabled={!inventoryId || !canEditPart}
					value={part.description ?? ''}
					on:change={event => {
						if (event.target instanceof HTMLTextAreaElement) {
							part.description = event.target.value
						}
					}}
				/>
			</div>
		</div>
	</div>
	<div class="col-lg d-flex flex-wrap">
		<div class="card hightlight-card mt-4 w-100">
			<h4 class="card-header">Internal Notes</h4>
			<div class="card-body">
				<Textarea
					placeholder="Internal notes won't appear on any customer-facing reports or online."
					label="Internal Notes"
					labelClass="sr-only"
					for="notes"
					rows="6"
					disabled={!inventoryId || !canEditPart}
					value={part.notes ?? ''}
					on:change={event => {
						if (event.target instanceof HTMLTextAreaElement) {
							part.notes = event.target.value
						}
					}}
				/>
			</div>
		</div>
	</div>
	<div class="col-lg-4 d-flex flex-wrap">
		<div class="card hightlight-card mt-4 w-100">
			<h4 class="card-header">Shipping</h4>
			<div class="card-body">
				<div class="form-row">
					<div class="col-md">
						<Input
							id="shipping-weight"
							inputmode="decimal"
							type="number"
							label="Weight"
							disabled={!inventoryId || !canEditPart}
							value={part.weight}
							on:change={event => numberPropChange(event, 'weight')}
						></Input>
					</div>
					<div class="col-md">
						<Input
							id="shipping-length"
							inputmode="decimal"
							type="number"
							label="Length"
							disabled={!inventoryId || !canEditPart}
							value={part.shippingLength}
							on:change={event => numberPropChange(event, 'shippingLength')}
						></Input>
					</div>
				</div>

				<div class="form-row">
					<div class="col-md">
						<Input
							id="shipping-width"
							inputmode="decimal"
							type="number"
							label="Width"
							disabled={!inventoryId || !canEditPart}
							value={part.shippingWidth}
							on:change={event => numberPropChange(event, 'shippingWidth')}
						/>
					</div>

					<div class="col-md">
						<Input
							id="shipping-height"
							inputmode="decimal"
							type="number"
							label="Height"
							disabled={!inventoryId || !canEditPart}
							value={part.shippingHeight}
							on:change={event => numberPropChange(event, 'shippingHeight')}
						></Input>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- New Part Modal -->
<Modal
	title="Create New Part"
	subtitle="Enter a part type to get started"
	closeShown={false}
	confirmButtonText="Create Part"
	confirmButtonDisabled={!part.inventoryTypeId}
	cancelButtonText="Cancel"
	backdropClickCancels={false}
	bind:show={showCreatePartModal}
	on:close={() => window.history.back()}
	on:confirm={() => onCreatePart(part)}
>
	<PartTypeManModCat
		inventoryTypeInputId="createPartInventoryTypeInput"
		manufacturerShown={false}
		modelShown={false}
		categoryShown={false}
		bind:inventoryTypeId={part.inventoryTypeId}
	/>

	<StoreSelect
		storeList={stores}
		showAllOption={false}
		bind:selectedStoreId={part.storeId}
	/>

	{#if frequentlyUsedInventoryTypes && frequentlyUsedInventoryTypes.length > 0}
		<hr />
		<h5 class="mb-0">Frequently used part types</h5>
		<small>Click to create</small>
		<div class="form-row mt-3">
			{#each frequentlyUsedInventoryTypes as type}
				<div class="col-12 col-md-6 mb-2">
					<Button
						outline
						size="sm"
						class="w-100"
						on:click={() => {
							inventoryType = type
							onCreatePart({
								...part,
								inventoryTypeId: type.inventoryTypeId,
							})
						}}>{type.name}</Button
					>
				</div>
			{/each}
		</div>
	{/if}
</Modal>

<!-- Tracking Number Disambiguation Modal -->
<Modal
	title="Pick Vehicle - Tracking #: {lookupTrackingNumber}"
	closeShown={true}
	cancelShown={false}
	confirmShown={false}
	bind:show={showTrackingNumberDisambiguationModal}
	on:close={cancelVehicleDisambiguation}
>
	<Table
		rows={trackingNumberDisambigList}
		columns={[
			{ property: 'make', name: 'Make' },
			{ property: 'model', name: 'Model' },
			{ property: 'year', name: 'Year' },
		]}
		perPageCount={5}
		let:row
	>
		<tr
			class="cursor-pointer"
			on:click={() => setVehicle(row)}
		>
			<td>{row.make}</td>
			<td>{row.model}</td>
			<td>{row.year}</td>
		</tr>
	</Table>
</Modal>

<!-- Tag Number Disambiguation Modal -->
<Modal
	bind:show={showParentPartTagNumberDisambiguationModal}
	title="Pick Assembly - Tag #: {lookupParentPartTagNumber}"
	modalSize="lg"
	cancelShown={false}
	confirmShown={false}
	closeShown={true}
	on:close={cancelAssemblyDisambiguation}
>
	<Table
		rows={displayParentPartTagNumberDisambigList}
		columns={[
			{ property: 'inventoryTypeName', name: 'Part Type', sortType: 'ALPHA_NUM' },
			{ property: 'partManufacturer', name: 'Manufacturer' },
			{ property: 'partModel', name: 'Part Model' },
			{ property: 'category', name: 'Category' },
			{ property: 'make', name: 'Vehicle Make' },
			{ property: 'model', name: 'Vehicle Model' },
		]}
		perPageCount={5}
		let:row
	>
		<tr
			class="cursor-pointer"
			on:click={() => setAssembly(row)}
		>
			<td>{row.inventoryTypeName}</td>
			<td>{row.partManufacturer}</td>
			<td>{row.partModel}</td>
			<td>{row.category}</td>
			<td>{row.make}</td>
			<td>{row.model}</td>
		</tr>
	</Table>
</Modal>

<!-- <partPricingModal hasAnyPricing={hasPricingOptions} /> -->
<PartPricingModal
	bind:this={partPricingModal}
	on:pricingChange={onPricingChange}
></PartPricingModal>
