import template from './sale.html'
import { stringToBoolean as isTrue } from '@isoftdata/utility-string'
import staticDocumentTypes from 'shared/sale-document-types'
import * as currency from '@isoftdata/utility-currency'
import financialNumber from 'financial-number'
import formatISO from 'date-fns/formatISO'
import addDays from 'date-fns/addDays'
import camelCase from 'camelcase'
import domValue from 'dom-value'
import deepCopy from 'klona'
import { v4 as uuid } from '@lukeed/uuid'
import pProps from 'p-props'
import toTitleCase from 'to-title-case'
import { stringToBoolean } from '@isoftdata/utility-string'

//Ractive Components
import makeReportSelectionModal from 'components/report-selection'
import makeUserPermissionAuthModal from 'components/user-permission-auth-modal'
import makeEntitySelectionModal from 'components/entity-selection-modal'
import makeSaleDocumentLineRow from 'components/sale-document-line-row'
import makeDocumentLoadModal from 'components/document-load-modal'
import makePayment from 'components/payment'
import makeCurrencyInput from '@isoftdata/currency-input'
import makeAddressCard from '@isoftdata/address-card'
import makeSplitButton from '@isoftdata/split-button'
import makeYesNoModal from '@isoftdata/yes-no-modal'
import makeTextArea from '@isoftdata/textarea'
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'
import makeModal from '@isoftdata/modal'
import makeTable from '@isoftdata/table'
import makeInput from '@isoftdata/input'

//Classes
import SalesOrderDocumentLineFromInventory from 'shared/classes/SalesOrderDocumentLineFromInventory'
import SalesOrderDocumentLineFromVehicle from 'shared/classes/SalesOrderDocumentLineFromVehicle'
import SalesOrderDocumentLine from 'shared/classes/SalesOrderDocumentLine'
import SalesOrderDocument from 'shared/classes/SalesOrderDocument'

const findValueInObject = (value, object, keysToCheck) => {
	let match

	if (value && keysToCheck?.length > 0) {
		for (const key in object) {
			if (keysToCheck.includes(key)) {
				if (object[key] == value) {
					match = key
					break
				}
			}
		}
	}

	return match
}

//save states
// 'NEW'
// 'MODIFIED'
// 'UNMODIFIED'

const tableColumns = [
	{
		property: 'tagNumber',
		name: 'Tag #',
		sortType: 'alphaNum',
		hiddenForDocumentTypes: [],
		requiredViewPermissions: [],
	},
	{
		property: 'trackingNumber',
		name: 'Tracking #',
		sortType: 'alphaNum',
		hiddenForDocumentTypes: [],
		requiredViewPermissions: [],
		hideForPrint: true,
	},
	{
		property: 'cost',
		name: 'Cost',
		columnMinWidth: '80px',
		hiddenForDocumentTypes: [],
		requiredViewPermissions: [ 'PM_PARTSCOST_VIEW' ],
		align: 'right',
		hideForPrint: true,
	},
	{
		property: 'coreAmount',
		name: 'Core',
		columnMinWidth: '80px',
		hiddenForDocumentTypes: [],
		requiredViewPermissions: [],
		align: 'right',
	},
	{
		property: 'price',
		name: 'Price',
		columnMinWidth: '120px',
		hiddenForDocumentTypes: [],
		requiredViewPermissions: [],
		align: 'right',
	},
	{
		property: 'quantity',
		name: 'Quantity',
		columnWidth: '5%',
		hiddenForDocumentTypes: [],
		requiredViewPermissions: [],
		align: 'right',
	},
	{
		property: 'isTaxed',
		name: 'Tax',
		hiddenForDocumentTypes: [],
		requiredViewPermissions: [],
		hideForPrint: true,
	},
	{
		property: 'isHeld',
		name: 'Hold',
		hiddenForDocumentTypes: [ 'INVOICE' ],
		requiredViewPermissions: [],
		hideForPrint: true,
	},
	{
		property: 'description',
		name: 'Description',
		columnWidth: '40%',
		hiddenForDocumentTypes: [],
		requiredViewPermissions: [],
	},
	{
		property: 'total',
		name: 'Total',
		columnMinWidth: '5%',
		hiddenForDocumentTypes: [],
		requiredViewPermissions: [],
		align: 'right',
	},
	{
		property: 'delete',
		icon: 'fas fa-trash-alt',
		hiddenForDocumentTypes: [],
		requiredViewPermissions: [],
		hideForPrint: true,
	},
]

const defaultDocumentType = 'INVOICE'
let documentTypes = staticDocumentTypes

export default function({ mediator, stateRouter, hasPermission, logAndAlert }) {
	const canViewDocumentType = type => {
		const docType = documentTypes.find(docType => docType.code === type)

		return hasPermission(docType.viewPermission)
	}

	stateRouter.addState({
		name: 'app.sale',
		route: 'sale',
		querystringParameters: [ 'documentId', 'documentType', 'printDocument', 'lastModifiedDate', 'printPicklist' ],
		defaultParameters: { documentType: defaultDocumentType },
		template: {
			template,
			data: {
				isTrue,
				domValue,
				currency,
				paymentsSlideoutShown: false,
			},
			components: {
				billingAddress: makeAddressCard(),
				shippingAddress: makeAddressCard(),
				itSelect: makeSelect(),
				itSelectTwo: makeSelect({ twoway: true, lazy: false }),
				itButton: makeButton(),
				loadDocumentModal: makeModal(),
				saveModal: makeModal(),
				paymentModal: makeModal(),
				showValidationErrorModal: makeModal(),
				priceAdjustmentModal: makeModal(),
				itYesNoModal: makeYesNoModal(),
				documentLinesTable: makeTable(),
				itTable: makeTable(),
				itTextArea: makeTextArea(),
				itTextAreaTwo: makeTextArea({ twoway: true, lazy: false }),
				itCurrencyInput: makeCurrencyInput(),
				itInput: makeInput(),
				itInputTwo: makeInput({ twoway: true, lazy: false }),
				saleDocumentLineRow: makeSaleDocumentLineRow(),
				payment: makePayment({ mediator, logAndAlert }, { twoway: false }),
				itSplitButton: makeSplitButton(),
				documentLoadModal: makeDocumentLoadModal(),
				customerSelectionModal: makeEntitySelectionModal(mediator),
				userPermissionAuthModal: makeUserPermissionAuthModal(mediator),
				reportSelectionModal: makeReportSelectionModal({ mediator, logAndAlert }),
			},
			hasPermission,
			setCustomerAddress(customer, type) {
				if (!type) {
					return
				}

				//map customer address keys to sale document address keys
				const customerKeyMapping = {
					'Company': 'Company',
					'Contact': 'Contact',
					'StreetAddress': 'Street',
					'MailingAddress': 'Mailing',
					'City': 'City',
					'State': 'State',
					'Zip': 'Zip',
					'Country': 'Country',
					'PhoneNumber': 'PhoneNumber',
				}

				const addressObject = Object.keys(customerKeyMapping).reduce((acc, sourceKey) => {
					return { ...acc, [`saleDocument.${type}${customerKeyMapping[sourceKey]}`]: customer[`${type}${sourceKey}`] }
				}, {})

				this.set(addressObject)
			},
			getCustomerDisplayName(customer) {
				let billingCompany = (customer && customer.billingCompany)
					? customer.billingCompany.trim()
					: ''

				let customerCompany = (customer && customer.billingContact)
					? customer.billingContact.trim()
					: ''

				return billingCompany || customerCompany
			},
			async getCustomerPricing(customerId) {
				const inventoryId = this.get('saleDocument.saleDocumentLines')
					.reduce((sum, item) => {
						return item.inventoryId ? [ ...sum, item.inventoryId ] : sum
					}, [])

				if (customerId && inventoryId?.length > 0) {
					return await mediator.call('emitToServer', 'load inventory price', { customerId, inventoryId })
				} else {
					return []
				}
			},
			getNextLineRank() {
				const lines = this.get('saleDocument.saleDocumentLines')
				const maxRank = lines.length ? Math.max(...lines.map(line => line.rank)) : -1
				console.log(lines.map(line => line.rank))
				console.log(maxRank + 1)
				return maxRank + 1
			},
			addDocumentLine({ SalesOrderDocumentLine, inventory, vehicle, index }) {
				const ractive = this

				const rank = ractive.getNextLineRank()

				SalesOrderDocumentLine = { ...SalesOrderDocumentLine, uuid: uuid(), rank }

				if (typeof index === 'number' && index > -1) {
					ractive.splice('saleDocument.saleDocumentLines', index, 1, SalesOrderDocumentLine)
				} else {
					ractive.push('saleDocument.saleDocumentLines', SalesOrderDocumentLine)
				}

				ractive.addInventory(inventory)
				ractive.addVehicle(vehicle)
			},
			clearNewLineRow() {
				const newSaleDocumentLineRow = this.findComponent('newSaleDocumentLineRow')

				if (newSaleDocumentLineRow) {
					newSaleDocumentLineRow.clear()
				}
			},
			newDocumentLineFromNewLine(saleDocumentLine) {
				const saleDocument = this.get('saleDocument')

				const salesOrderDocumentLine = new SalesOrderDocumentLine({
					saleDocument,
					documentType: saleDocument.documentType,
					saleDocumentLine,
					saveState: 'NEW',
				})

				this.addDocumentLine({ SalesOrderDocumentLine: salesOrderDocumentLine })
			},
			changeDocumentLine(prop, value, uuid) {
				const changedDocumentLines = this.get('saleDocument.saleDocumentLines').map(line => {
					if (line.uuid === uuid) {
						const saveState = line.saveState === 'NEW' ? 'NEW' : 'MODIFIED'
						return { ...line, [prop]: value, saveState }
					}
					return line
				})

				this.set({ 'saleDocument.saleDocumentLines': changedDocumentLines })
			},
			addVehicle(vehicle) {
				if (vehicle) {
					this.push('saleDocument.vehicleList', vehicle)
				}
			},
			addInventory(inventory) {
				if (inventory) {
					this.push('saleDocument.inventoryList', inventory)
				}
			},
			addFromSearch() {
				sessionStorage.setItem('returnState', 'app.sale')
				stateRouter.go('app.part-search')
			},
			addNewLine() {
				const ractive = this
				const saleDocument = ractive.get('saleDocument')

				const newLine = new SalesOrderDocumentLine({
					saleDocument,
					saveState: 'NEW',
					saleDocumentLine: {
						documentId: saleDocument.documentId,
						quantity: 1,
						rank: ractive.getNextLineRank(),
					},
				})

				ractive.push('saleDocument.saleDocumentLines', newLine).then(() => {
					const lookupInput = ractive.find(`#tag-number-input-${newLine.uuid}`)

					if (lookupInput) {
						lookupInput.select()
					}
				})
			},
			addItem(inventoryItem, inventoryTypeList, vehicleList, index) {
				const ractive = this

				const saleDocument = ractive.get('saleDocument')
				const newSaleLine = new SalesOrderDocumentLineFromInventory({
					saleDocument,
					inventory: inventoryItem,
					documentType: saleDocument.documentType,
					inventoryType: inventoryTypeList.find(inventoryType => inventoryType.inventoryTypeId === inventoryItem.inventoryTypeId),
					saveState: 'NEW',
					vehicle: vehicleList.find(vehicle => vehicle.vehicleId === inventoryItem.vehicleId),
				})

				const itemAlreadyExists = ractive.get('saleDocument.saleDocumentLines')
					.find(line => line.inventoryId === newSaleLine.inventoryId)

				if (itemAlreadyExists) {
					ractive.findComponent('itYesNoModal')
						.show({
							title: 'Add Item Again?',
							question: 'This item already exists. Add again?',
							yesText: 'Add Again',
							noText: 'Cancel',
							noCallback: 'clearNewLineRow',
							yesCallback: 'addDocumentLineConfirm',
							yesCallbackContext: { newSaleLine, index },
						})
				} else {
					let vehicle = null

					if (vehicleList && vehicleList.length > 0) {
						vehicle = vehicleList.find(vehicle => vehicle.vehicleId === inventoryItem.vehicleId)
					}

					ractive.addDocumentLine({
						SalesOrderDocumentLine: newSaleLine,
						inventory: inventoryItem,
						vehicle,
						index,
					})
				}
			},
			async performAddItemLookup(input, inventoryId, index) {
				const ractive = this

				let search = inventoryId ? { inventoryId } : { lookupString: input }

				const res = await mediator.call('emitToServer', 'sale item lookup', {
					...search,
					customerId: ractive.get('saleDocument.customer.customerId'),
				})

				const { inventoryList, vehicleList, inventoryTypes } = res
				if (inventoryId) {
					inventoryList.forEach(item => ractive.addItem(item, inventoryTypes, vehicleList, index))
				} else if (inventoryList.length === 1) {
					ractive.addItem(inventoryList[0], inventoryTypes, vehicleList, index)
				} else {
					//we should really do something.
					//Probably have a special set of
					//route params that the search screen can handle
				}
			},
			async save() {
				const ractive = this
				const saleDocument = ractive.get('saleDocument')
				const { customerId, accountLimit } = saleDocument.customer
				const documentBalance = ractive.get('documentBalance')

				if (ractive.get('missingRequiredFieldMessages').length > 0) {
					return ractive.set({ showValidationErrorModal: true })
				}

				if (saleDocument.documentType === 'INVOICE') {
					try {
						const res = await mediator.call('emitToServer', 'load customer balance', { customerId })
						const customerBalance = res[0].balance

						const hasBalance = financialNumber(documentBalance)
							.plus(customerBalance.toString())
							.gt((accountLimit && accountLimit.toString()) || '0')

						if (hasBalance) {
							if (hasPermission('PM_OVERRIDE_CREDIT_LIMIT')) {
								ractive.findComponent('itYesNoModal')
									.show({
										title: 'Override Credit Limit?',
										question: 'Saving this document will put this customer over their account limit.<br><br>Do you want to override?',
										yesText: 'Override & Save',
										noText: 'Cancel',
										yesCallback: 'saveConfirm',
									})
							} else {
								ractive.set({ showOverrideCreditLimitModal: true })
							}
						} else {
							ractive.saveConfirm()
						}
					} catch (err) {
						logAndAlert(err, mediator, 'Error getting customer balance for credit limit')
					}
				} else {
					// Want to set this on save so the user can still edit before saving/closing
					if (ractive.get('closeQuoteOnSave')) {
						ractive.set('saleDocument.isClosed', true)
					} else if (ractive.get('reopenQuoteOnSave')) {
						ractive.set('saleDocument.isClosed', false)
					}
					ractive.saveConfirm()
				}
			},
			async saveConfirm() {
				const ractive = this
				const saleDocument = ractive.get('saleDocument')

				//Filter non-zero payments. Maybe we should do this on the server?
				saleDocument.payments = saleDocument?.payments?.filter(payment => Number(payment.amount) !== 0) || []

				ractive.set({ saleSaving: true })
				try {
					const res = await mediator.call('emitToServer', 'save sale document', { saleDocument })

					const { documentType, documentId, lastModifiedDate } = res
					ractive.set({
						saleSaving: false,
						wantsDocumentCache: false, // we don't want to accidentally recache the document after removing it on save
					}).then(() => {
						//Make sure we clear the cache so they can't accidentially create a duplicate
						localStorage.removeItem('saleDocument')

						stateRouter.go(null, {
							documentType,
							documentId,
							lastModifiedDate,
							printDocument: ractive.get('printDocument'),
							printPicklist: ractive.get('printPicklist'),
						}, { replace: true, inherit: true })
					})
				} catch (err) {
					logAndAlert(err, mediator, 'An error occured saving the sale document')
					ractive.set({ saleSaving: false })
				}
			},
			changeCustomer() {
				const ractive = this

				const inventoryList = ractive.get('inventoryList')

				ractive.set('saleDocument.customer', {}).then(() => {
					const documentLinesPricing = ractive.get('saleDocument.saleDocumentLines').map(documentLine => {
						const item = inventoryList.find(inventory => inventory.inventoryId === documentLine.inventoryId)

						if (item) {
							return { ...documentLine, price: item.retailPrice }
						}
						return documentLine
					})

					ractive.set({
						['saleDocument.saleDocumentLines']: documentLinesPricing,
						['saleDocument.salesperson']: ractive.get('currentUser'),
						['saleDocument.taxCode']: ractive.get('defaultTaxCode.name'),
					})

					const billingAndShippingKeysWithEmptyValues = Object.keys(ractive.get('saleDocument'))
						.filter(key => key.startsWith('billing') || key.startsWith('shipping'))
						.reduce((acc, key) => {
							return { ...acc, [`saleDocument.${key}`]: '' }
						}, {})

					ractive.set({ ...billingAndShippingKeysWithEmptyValues })

					ractive.set({ 'customerLookup': '', 'saleDocument.customer': {} })
					ractive.find('#customerLookupInput').select()
				})
			},
			approveEcommerce() {
				this.set({ 'saleDocument.vendorApprovalStatus': 'Approved' })
			},
			rejectEcommerce() {
				this.set({ 'saleDocument.vendorApprovalStatus': 'Rejected' })
			},
			computed: {
				computedShippingIntegrationContact() {
					const selectedSalesPerson = this.get('selectedSalesPerson')
					const session = this.get('session')
					const { shippingContact } = this.get('externalShippingSettings')

					if (stringToBoolean(shippingContact.useDocumentSalesperson)) {
						return {
							firstName: selectedSalesPerson.firstName,
							lastName: selectedSalesPerson.lastName,
							email: selectedSalesPerson?.email || selectedSalesPerson?.recoveryEmail || session?.user?.email,
						}
					} else {
						return {
							firstName: shippingContact.firstName || '',
							lastName: shippingContact.lastName || '',
							email: shippingContact.email || '',
						}
					}
				},
				allPaymentsAreValid() {
					const payments = this.get('saleDocument.payments')
					const validPayments = payments.filter(payment => {
						return payment.paymentId || (Number(payment.amount) && payment.paymentMethodName)
					})

					return validPayments.length === payments.length
				},
				showExpired() {
					return this.get('saleDocument.documentType') !== 'INVOICE'
				},
				customerTaxNumber() {
					const saleDocument = this.get('saleDocument')

					if (saleDocument && saleDocument.customer && saleDocument.customer.taxNumber) {
						return `Tax #: ${saleDocument.customer.taxNumber}`
					}
					return false
				},
				externalShippingJSON() {
					const sale = this.get('saleDocument')
					const saleLines = this.get('saleDocument.saleDocumentLines')
					const store = this.get('store')

					return JSON.stringify({
						sourceStore: {
							storeID: store.storeId,
							code: store.storeId?.toString(),
							number: store.storeId?.toString(),
							name: store.name,
							address: store.address,
							city: store.city,
							state: store.state,
							zip: store.zip,
							country: store.country,
							phone: store.phoneNumber,
							fax: store.faxNumber,
							invoiceMemo: store.invoiceMemo,
							webAddress: store.website,
							logoFileID: store.logoFileId,
						},
						salesOrder: {
							billingCity: sale.billingCity,
							billingCompany: sale.billingCompany,
							billingContact: sale.billingContact,
							billingCountry: sale.billingCountry,
							billingMailing: sale.billingMailing,
							billingPhone: sale.billingPhoneNumber,
							billingState: sale.billingState,
							billingStreet: sale.billingStreet,
							billingZip: sale.billingZip,
							closed: true,
							customerID: sale.customer.customerId,
							date: sale.documentDate,
							dateClosed: sale.creationDate,
							dateEntered: sale.creationDate,
							dateModified: sale.lastModifiedDate,
							externalComments: sale.comments,
							internalComments: '', //Pro doesn't have internal comments
							purchaseOrderNumber: sale.purchaseOrderId,
							salesOrderID: sale.documentId,
							salesOrderLines: saleLines.map(line => {
								const inventoryItem = this.get('saleDocument.inventoryList')?.find(part => part.inventoryId === line.inventoryId)

								return {
									salesOrderLineID: line.saleDocumentLineId ?? null,
									inventoryID: line.inventoryId ?? null,
									inventoryStoreID: inventoryItem?.storeId ?? null,
									inventoryTypeID: inventoryItem?.inventoryTypeId ?? null,
									description: line.description,
									lookup: line.tagNumber,
									type: line.inventoryId ? 'Inventory' : 'Misc',
									price: line.price,
									quantity: line.quantity,
									inventory: inventoryItem ? {
										weight: inventoryItem.weight,
										weightUnit: 'lb',
										shippingWidth: inventoryItem.shippingWidth,
										shippingLength: inventoryItem.shippingLength,
										shippingHeight: inventoryItem.shippingHeight,
										shippingMeasurementUnit: 'in',
									} : {},
								}
							}),
							shipCity: sale.shippingCity,
							shipCompany: sale.shippingCompany,
							shipContact: sale.shippingContact,
							shipCountry: sale.shippingCountry,
							shipMailing: sale.shippingMailing,
							shipPhone: sale.shippingPhoneNumber,
							shipState: sale.shippingState,
							shipStreet: sale.shippingStreet,
							shipZip: sale.shippingZip,
							storeID: 1,
							subtotal: sale.subtotal,
							tax: sale.tax,
							void: sale.status === 'Void',
						},
					})
				},
				cashOnlyCustomerHasZeroBalance() {
					const saleDocument = this.get('saleDocument')

					const isCashOnly = isTrue(saleDocument?.customer?.cashOnly) // this could have a cached "True"/"False" from localStorage
					return (isCashOnly && saleDocument.documentType === 'INVOICE') ? Number(this.get('documentBalance')) === 0 : true
				},
				missingRequiredFieldMessages() {
					const saleDocument = this.get('saleDocument')
					let messages = []

					if (!saleDocument?.customer?.customerId) {
						messages.push('You must select a customer')
					}

					if (!this.get('hasRequiredPONumber')) {
						messages.push('This customer requires that you enter a PO # before you can complete the sales order')
					}

					if (!this.get('hasAddEditPermission')) {
						messages.push('You don\'t have permission to save')
					}

					if (!saleDocument.salesperson) {
						messages.push('You must select a salesperson')
					}

					if (!this.get('cashOnlyCustomerHasZeroBalance')) {
						messages.push('Cash only customers cannot have a remaining unpaid balance')
					}

					if (saleDocument.saleDocumentLines.length === 0) {
						messages.push('You must have at least one lineitem')
					}

					return messages
				},
				hasRequiredPONumber() {
					const poRequired = this.get('saleDocument.customer.poRequired')
					const purchaseOrderNumber = this.get('saleDocument.purchaseOrderId')

					return isTrue(poRequired) ? !!purchaseOrderNumber : true // this could have a cached "True"/"False" from localStorage
				},
				paymentInfo() {
					return deepCopy({
						documentBalance: this.get('documentBalance'),
						documentId: this.get('saleDocument.documentId'),
						customerId: this.get('saleDocument.customer.customerId'),
					})
				},
				canEdit() {
					const saleDocument = this.get('saleDocument')

					return !(saleDocument.documentType === 'INVOICE' && [ 'MODIFIED', 'UNMODIFIED' ].includes(saleDocument.saveState))
				},
				hasAddEditPermission() {
					const documentTypeCode = this.get('saleDocument.documentType')
					const documentType = documentTypes.find(docType => docType.code === documentTypeCode)

					if (documentType.addEditPermission) {
						return hasPermission(documentType.addEditPermission)
					}
					return true
				},
				displaySalesPeople() {
					return this.get('salesPeople').reduce((sum, salesperson) => {
						if (salesperson.userName === this.get('saleDocument.salesperson') || salesperson.status === 'Active') {
							let displayName = (salesperson.firstName && salesperson.lastName)
								? `${salesperson.firstName } ${ salesperson.lastName}`
								: salesperson.userName

							displayName += salesperson.status !== 'Active' ? ' (Inactive)' : ''

							return sum.concat({ ...salesperson, displayName })
						}
						return sum
					}, [])
				},
				selectedSalesPerson() {
					return this.get('salesPeople').find(salesPerson => salesPerson.userName === this.get('saleDocument.salesperson'))
				},
				nonTaxedLinesTotal() {
					return this.get('saleDocument.saleDocumentLines').reduce((sum, line) => {
						if (line.saveState === 'DELETE') {
							return sum
						}
						const price = line.price ? line.price : 0
						const quantity = line.quantity ? line.quantity : 0
						const core = line.coreAmount ? line.coreAmount : 0

						const lineTotal = financialNumber(price.toString())
							.plus(core.toString())
							.times(quantity.toString())

						return !line.isTaxed ? sum.plus(lineTotal.toString()) : sum
					}, financialNumber('0')).toString()
				},
				taxedLinesTotal() {
					return this.get('saleDocument.saleDocumentLines').reduce((sum, line) => {
						if (line.saveState === 'DELETE') {
							return sum
						}
						const price = line.price ? line.price : 0
						const quantity = line.quantity ? line.quantity : 0
						const core = line.coreAmount ? line.coreAmount : 0

						const lineTotal = financialNumber(price.toString())
							.plus(core.toString())
							.times(quantity.toString())

						return line.isTaxed ? sum.plus(lineTotal.toString()) : sum
					}, financialNumber('0')).toString()
				},
				totalPaid() {
					const saleId = this.get('saleDocument.documentId')

					return this.get('saleDocument.payments').reduce((sum, payment) => {
						if (((!saleId && payment.saleId === null) || payment.saleId === saleId) && payment.saveState !== 'TO_VOID') {
							const paymentAmount = payment.amount ? payment.amount : 0

							return sum.plus(paymentAmount)
						}
						return sum
					}, financialNumber('0')).toString()
				},
				displaySubtotal() {
					return currency.format(this.get('saleDocument.subtotal'))
				},
				displayTax() {
					return currency.format(this.get('saleDocument.tax'))
				},
				displayTotal() {
					return currency.format(this.get('saleDocument.total'))
				},
				displayTaxItems() {
					const documentTaxCode = this.get('saleDocument.taxCode')
					return this.get('taxItems')
						.map(taxItem => {
							const displayRate = `${financialNumber(taxItem.rate.toString())
								.times('100').toString()}%`

							return {
								...taxItem,
								displayName: `${taxItem.name} (${displayRate})${!taxItem.active ? ' (Inactive)' : ''}`,
								displayRate,
							}
						}).filter(taxItem => taxItem.active || taxItem.name === documentTaxCode)
				},
				displayCurrentTaxItem() {
					const saleDocument = this.get('saleDocument')

					let displayTaxItem = this.get('displayTaxItems')
						.find(taxItem => taxItem.name === saleDocument.taxCode)

					if (!displayTaxItem) {
						displayTaxItem = {
							displayName: '',
							displayRate: '0%',
						}
					}

					return displayTaxItem
				},
				displayTotalPaid() {
					return currency.format(this.get('totalPaid'))
				},
				currentDocumentType() {
					return this.get('documentTypes')
						.find(documentType => this.get('saleDocument.documentType') === documentType.code)
				},
				currentDocumentTypeName() {
					return this.get('currentDocumentType').name
				},
				selectedLoadDocumentDocumentType() {
					return this.get('documentTypes').find(documentType => this.get('selectedLoadDocumentTypeCode') === documentType.code)
				},
				displayCustomerBalance() {
					return currency.format(this.get('customerBalance'), { includeSymbol: true })
				},
				displayDocumentBalance() {
					return currency.format(this.get('documentBalance'))
				},
				displayCustomerName() {
					return this.getCustomerDisplayName(this.get('saleDocument.customer'))
				},
				customerPercentOfPrice() {
					return this.get('saleDocument.customer.percentOfPrice') || 100
				},
				customerPriceType() {
					const customerDefaultPriceType = this.get('saleDocument.customer.defaultPriceType')
					return this.get('customerPriceTypes').find(type => type.name === customerDefaultPriceType)
				},
				computedAdjustedPrice() {
					const percentage = this.get('priceAdjustmentModal.customPricePercentage') || 0
					const priceTypeKey = this.get('priceAdjustmentModal.selectedPriceTypeKey')

					let price = '0'

					//null priceType means custom
					if (priceTypeKey === null) {
						price = this.get('priceAdjustmentModal.customPrice')
					} else {
						price = this.get(`priceAdjustmentModal.part.${priceTypeKey}`)
					}

					return financialNumber((price || 0).toString())
						.times(financialNumber(percentage.toString()).times('0.01'))
						.toString(2)
				},
				customerLookupPlaceholder() {
					const name = this.get('displayCustomerName')
					return name ? `Current: ${ this.get('displayCustomerName')}` : ''
				},
				displaySaleLines() {
					const inventoryList = this.get('saleDocument.inventoryList')
					const vehicleList = this.get('saleDocument.vehicleList')

					return this.get('saleDocument.saleDocumentLines').map(saleLine => {
						const foundInventory = inventoryList.find(part => part.inventoryId === saleLine.inventoryId)
						const foundVehicle = vehicleList.find(vehicle => vehicle.vehicleId === saleLine.vehicleId)

						return {
							...saleLine,
							tagNumber: foundInventory ? foundInventory.tagNumber : '',
							trackingNumber: foundVehicle ? foundVehicle.trackingNumber : '',
							price: currency.format(saleLine.price, { includeSymbol: false, includeComma: false }),
							total: currency.format(saleLine.price * saleLine.quantity),
						}
					})
				},
				displayTableColumns() {
					const documentType = this.get('saleDocument.documentType')
					let tableColumns = this.get('tableColumns')

					return tableColumns.filter(column => {
						let canView = true

						for (let permission of column.requiredViewPermissions) {
							if (!hasPermission(permission)) {
								canView = false
								break
							}
						}

						return canView && !column.hiddenForDocumentTypes.includes(documentType)
					})
				},
				documentBalance() {
					const totalPaid = this.get('totalPaid')
					const total = this.get('saleDocument.total')

					return financialNumber(total.toString())
						.minus(totalPaid.toString())
						.toString(2)
				},
				hasZeroDocumentBalance() {
					return financialNumber(this.get('documentBalance').toString())
						.equal('0')
				},
				hasZeroCustomerBalance() {
					return financialNumber(this.get('customerBalance').toString())
						.equal('0')
				},
				authorizedDocumentTypes() { // Do not allow a user to choose a document type they are not allowed to view
					return this.get('documentTypes').map(doc => {
						return {
							...doc,
							disabled: !canViewDocumentType(doc.code),
						}
					})
				},
				canChangeTaxType() {
					if (this.get('saleDocument.documentType') === 'INVOICE') {
						return hasPermission('PM_INVOICE_CHANGE_TAX_TYPE')
					} else {
						return true
					}
				},
				canEditLineItemTaxability() {
					if (this.get('saleDocument.documentType') === 'INVOICE') {
						return hasPermission('PM_INVOICE_CHANGE_LINE_ITEM_TAXABILITY')
					} else {
						return true
					}
				},
				isClosedQuote() {
					return this.get('saleDocument.documentType') !== 'INVOICE' && this.get('saleDocument.isClosed')
				},
			},
		},
		async resolve(data, { documentId, documentType, printDocument, printPicklist }) {
			if (!hasPermission('PM_INVOICE_VIEW')) {
				throw { redirectTo: { name: 'login' } }
			}

			if (sessionStorage.getItem('returnState') === 'app.sale') {
				sessionStorage.removeItem('returnState')
			}

			const cachedSaleDocument = JSON.parse(localStorage.getItem('saleDocument'))

			if (cachedSaleDocument && !cachedSaleDocument.documentId) {
				cachedSaleDocument.documentDate = formatISO(new Date(), { representation: 'date' })
			}

			if (!documentTypes.map(documentType => documentType.code).includes(documentType)) {
				throw {
					redirectTo: {
						name: null,
						params: {
							documentId,
							documentType: defaultDocumentType,
						},
					},
				}
			}

			if (!canViewDocumentType(documentType) && documentId) {
				throw { redirectTo: { name: 'not-found' } }
			}

			if (documentType !== 'BUILD_ORDER') {
				documentTypes = documentTypes.filter(type => type.code !== 'BUILD_ORDER')
			}
			const { taxItems, documentHasNewerChanges } = await pProps({
				// Need to get all tax items for old invoices with inactive tax items; inactive tax items will not be selectable\
				taxItems: mediator.call('emitToServer', 'load tax items', { activeOnly: true }),
				// If a sale document has been modified after the cached version,
				documentHasNewerChanges: (cachedSaleDocument && cachedSaleDocument.documentId && !documentId) ? mediator.call('emitToServer', 'sale document has newer changes', {
					documentId: cachedSaleDocument.documentId,
					documentType: cachedSaleDocument.documentType,
					lastModifiedDate: cachedSaleDocument.lastModifiedDate,
				}) : false,
			})

			if (documentHasNewerChanges) {
				alert(`${cachedSaleDocument.documentType.toUpperCase() === 'INVOICE' ? 'Invoice' : 'Quote'} #${cachedSaleDocument.documentId} has been saved by another user. Any unsaved changes will be discarded, and the document will be reloaded from the server.`)
				documentId = cachedSaleDocument.documentId
				documentType = cachedSaleDocument.documentType
				localStorage.removeItem('saleDocument')
			}

			const defaultTaxCode = taxItems.find(({ isDefault }) => isDefault)

			let session = localStorage.getItem('session')
			session = session ? JSON.parse(session) : {}
			let salesperson = session.userName || ''

			let newDocumentTemplate = {
				quoteId: undefined,
				storeId: session.storeId,
				documentDate: formatISO(new Date(), { representation: 'date' }),
				creationDate: null,
				salesperson,
				taxCode: defaultTaxCode?.name || taxItems[0].name,
				tax: 0,
				subtotal: 0,
				total: 0,
				purchaseOrderId: '',
				comments: '',
				lastModifiedDate: null,
				shippingCarrier: '',
				shippingCompany: '',
				shippingContact: '',
				shippingStreet: '',
				shippingMailing: '',
				shippingCity: '',
				shippingState: '',
				shippingZip: '',
				shippingCountry: '',
				shippingPhoneNumber: '',
				unbalanceSaleList: [],
				webSaleId: null,
				webSaleType: 'None',
				vendorApprovalStatus: 'Pending',
				customerApprovalStatus: 'Pending',
			}

			if (documentType === 'INVOICE') {
				newDocumentTemplate = {
					...newDocumentTemplate,
					saleId: undefined,
				}
			}

			Object.freeze(newDocumentTemplate)

			const newSalesOrderDocument = new SalesOrderDocument({
				saleDocument: newDocumentTemplate,
				saleDocumentLines: [],
				customer: {},
				payments: [],
				documentType,
				vehicleList: [],
				inventoryList: [],
				saveState: 'NEW',
			})

			const loadStoreSetting = async options => {
				return (await mediator.call('emitToServer', 'load store setting', options))?.[0]?.value
			}

			const stageOneRes = await pProps({
				salesPeople: mediator.call('emitToServer', 'load users', {}),
				saleDocument: (documentId && documentType) ? mediator.call('emitToServer', 'load sale document', {
					documentType,
					saleDocumentId: documentId,
				}) : (cachedSaleDocument || newSalesOrderDocument),
				eCommerceHtpIntegrationInstalled: mediator.call('emitToServer', 'check global setting', {
					name: 'eCommerce: HeavyTruckParts.net integration installed',
					category: 'Integrations',
					settingType: 'Important Configuration',
					defaultValue: 'False' }),
				externalShippingSettings: pProps({
					'Enable external shipping': mediator.call('emitToServer', 'check global setting', {
						name: 'Enable external shipping',
						settingType: 'Optional Configuration',
						defaultValue: 'False',
						category: 'External Shipping Integration',
					}),
					/*
					'Enable FPG shipping': mediator.call('emitToServer', 'check global setting', {
						name: 'Enable FPG shipping',
						settingType: 'Optional Configuration',
						defaultValue: 'False',
						category: 'External Shipping Integration',
					}),
					*/
					'Customer account number': mediator.call('emitToServer', 'check global setting', {
						name: 'Customer account number',
						settingType: 'Optional Configuration',
						category: 'External Shipping Integration',
					}),
					'Billing location code': mediator.call('emitToServer', 'check global setting', {
						name: 'Billing location code',
						settingType: 'Optional Configuration',
						category: 'External Shipping Integration',
					}),
					'Integration URL': mediator.call('emitToServer', 'check global setting', {
						name: 'Integration URL',
						settingType: 'Optional Configuration',
						category: 'External Shipping Integration',
						defaultValue: 'https://shipping.isoftdata.com/fpg',
					}),
					'Local shipping markup': mediator.call('emitToServer', 'check global setting', {
						name: 'Local shipping markup',
						settingType: 'Optional Configuration',
						category: 'External Shipping Integration',
						defaultValue: '0',
					}),
					'Testing mode': mediator.call('emitToServer', 'check global setting', {
						name: 'Testing mode',
						settingType: 'Optional Configuration',
						category: 'External Shipping Integration',
						defaultValue: 'False',
					}),
					'Disable shipment scheduling': mediator.call('emitToServer', 'check global setting', {
						name: 'Disable shipment scheduling',
						settingType: 'Optional Configuration',
						category: 'External Shipping Integration',
						defaultValue: 'False',
					}),
					'shippingContact': pProps({
						useDocumentSalesperson: loadStoreSetting({ settingName: 'Use salesperson for shipping contact', settingLocation: 'External Shipping Integration' }),
						firstName: loadStoreSetting({ settingName: 'Shipping contact first name', settingLocation: 'External Shipping Integration' }),
						lastName: loadStoreSetting({ settingName: 'Shipping contact last name', settingLocation: 'External Shipping Integration' }),
						email: loadStoreSetting({ settingName: 'Shipping contact email', settingLocation: 'External Shipping Integration' }),
					}),
				}),
				store: (async() => (await mediator.call('emitToServer', 'load stores', { storeId: 1 }))?.[0])(),
				taxItems,
				documentTypes,
				tableColumns,
				wantsDocumentCache: true,
				currentDocumentTypeCode: documentType,
				selectedLoadDocumentTypeCode: documentType,
				loadDocumentModalShown: false,
				chooseCustomerModalShown: false,
				customerPriceTypes: [
					{ key: 'retailPrice', name: 'Retail' },
					{ key: 'wholesalePrice', name: 'Wholesale' },
					{ key: 'listPrice', name: 'List' },
					{ key: 'cost', name: 'Cost' },
				],
				priceAdjustmentModal: {
					show: false,
					line: {},
					part: {},
				},
				loadDocumentDocumentNumber: '',
				documentNotFound: false,
				currentUser: session.userName,
				defaultTaxCode,
				doPrintDocument: isTrue(printDocument),
				doPrintPicklist: isTrue(printPicklist),
				customerBalance: 0,
				canEditPrice: hasPermission('PM_PARTFINANCIAL_EDIT'),
				canVoidSale: hasPermission('PM_INVOICE_VOID'),
				session,
			})

			let saleSaveOptions = localStorage.getItem('saleSaveOptions')

			if (saleSaveOptions) {
				saleSaveOptions = JSON.parse(saleSaveOptions)
			}

			const stageTwoRes = {
				newSaleLineRowTemplate: new SalesOrderDocumentLine({
					saleDocument: stageOneRes.saleDocument,
					saleDocumentLine: {
						quantity: 1,
						isTaxed: true,
						description: '',
						price: 0.00,
					},
					documentType: stageOneRes.saleDocument.documentType,
					currency,
					saveState: 'NEW',
				}),
			}

			stageOneRes.documentTypes = stageOneRes.documentTypes.map(docType => {
				const expireKey = Object.keys(stageOneRes.store).find(key => `${camelCase(docType.code).toLowerCase()}Expire` === key)

				return { ...docType, expiresDays: parseInt(stageOneRes.store[expireKey], 10) }
			})

			return {
				...stageOneRes,
				...stageTwoRes,
				printDocument: !!(saleSaveOptions && saleSaveOptions.printDocument),
				printPicklist: !!(saleSaveOptions && saleSaveOptions.printPicklist),
				rememberSaleSaveOptions: !!localStorage.getItem('saleSaveOptions'),
				shippingMethod: 'TForce', //Currently the only provider
			}
		},
		activate(context) {
			const { domApi: ractive, parameters } = context

			if (ractive.get('saleDocument.documentId')) {
				//get everything buy printDocument, as we don't want to cache that with the activity params
				// eslint-disable-next-line no-unused-vars
				const { printDocument, printPicklist, ...validParams } = parameters

				mediator.call('activity', {
					stateName: 'app.sale',
					stateParameters: validParams,
					stateCategory: 'SALE',
					action: 'VIEW',
					displayTitle: `${toTitleCase(ractive.get('saleDocument.documentType'))} #${ractive.get('saleDocument.documentId')}`,
					stateParameterKey: 'documentId',
				})
			}

			const customerLookupInput = ractive.find('#customerLookupInput')

			if (customerLookupInput) {
				customerLookupInput.select()
			}

			if (ractive.get('doPrintDocument')) {
				const saleDocument = ractive.get('saleDocument')
				const reportJob = {
					type: saleDocument.documentType === 'INVOICE' ? 'Invoice' : 'Quote',
					reportParameters: [
						{ parameterName: saleDocument.documentType === 'INVOICE' ? 'invoicenum' : 'quotenum', value: parseInt(saleDocument.documentId, 10) },
					],
				}
				const email = ractive.get('saleDocument.customer.emailAddress')
				ractive.findComponent('reportSelectionModal').printReport(reportJob, { [email]: email }, {}, () => {
					stateRouter.go(null, { printDocument: 'false' }, { inherit: true })
				})
			}

			if (ractive.get('doPrintPicklist')) {
				const saleDocument = ractive.get('saleDocument')
				if (saleDocument.documentId) {
					// Invoice vs Quote picklist
					const reportJob = {
						type: saleDocument.documentType === 'INVOICE' ? 'Picklist' : 'QuotePickList',
						reportParameters: [ // double check parameters
							{ parameterName: 'invoicenum', value: parseInt(saleDocument.documentId, 10) },
							{ parameterName: 'document', value: saleDocument.documentType === 'INVOICE' ? 'Invoice' : 'Quote' },
						],
					}
					ractive.findComponent('reportSelectionModal').printReport(reportJob, {}, {}, () => {
						stateRouter.go(null, { printPicklist: 'false' }, { inherit: true })
					})
				}
			}

			const logoFileId = ractive.get('store.logoFileId')

			if (logoFileId) {
				mediator.call('emitToServer', 'load file info', { fileId: ractive.get('store.logoFileId') })
					.then(logoFile => ractive.set({ logoFile }))
			}

			ractive.on('customerLookup', (context, event, lookup) => {
				event.original.preventDefault()
				if (!ractive.findComponent('customerSelectionModal')?.get('show')) {
					ractive.findComponent('customerSelectionModal').fire('entityLookup', {}, lookup)
				}
			})

			if (sessionStorage.getItem('pending-app.sale-item-add')) {
				const pendingItemAdd = JSON.parse(sessionStorage.getItem('pending-app.sale-item-add'))

				if (pendingItemAdd && pendingItemAdd.inventoryIds && pendingItemAdd.inventoryIds.length > 0) {
					ractive.performAddItemLookup(null, pendingItemAdd.inventoryIds)
				}

				sessionStorage.removeItem('pending-app.sale-item-add')
			}

			ractive.observe('currentDocumentType', type => {
				let expireDate = null

				if (type && type.expiresDays) {
					expireDate = formatISO(addDays(new Date(), type.expiresDays), { representation: 'date' })
				}

				ractive.set('saleDocument.expirationDate', expireDate)
			})

			context.on('destroy', () => {
				if (ractive.get('wantsDocumentCache')) {
					const sale = ractive.get('saleDocument')

					if (sale.saveState !== 'UNMODIFIED') {
						localStorage.setItem('saleDocument', JSON.stringify(sale))
					}
				}
			})

			ractive.on('customerChosen', (context, customer) => {
				if (customer === undefined) {
					console.log("[customerChosen handler] customer is undefined, abort")
					return
				}
				const customerLookupValue = customer.label
				customer = customer.data

				ractive.getCustomerPricing(customer.customerId).then(pricing => {
					const documentLinesPricing = ractive.get('saleDocument.saleDocumentLines').map(documentLine => {
						const inventoryPricingMatch = pricing.find(pricing => pricing.inventoryId === documentLine.inventoryId)

						if (inventoryPricingMatch) {
							return {
								...documentLine,
								price: inventoryPricingMatch.customerPrice,
							}
						}
						return documentLine
					})

					ractive.set('saleDocument.saleDocumentLines', documentLinesPricing)
				})

				//Default Salesperson
				const foundSalesPerson = ractive.get('salesPeople')
					.filter(salesPerson => salesPerson.status === 'Active')
					.find(salesPerson => salesPerson.userName === customer.defaultSalesperson)

				//Default Tax Item
				const foundTaxItem = ractive.get('taxItems')
					.find(taxItem => taxItem.name === customer.taxItem && taxItem.active)

				ractive.set({
					customerLookupValue,
					['saleDocument.customer']: customer,
				})

				if (foundSalesPerson) {
					ractive.set({ ['saleDocument.salesperson']: customer.defaultSalesperson })
				}

				if (foundTaxItem) {
					ractive.set({ ['saleDocument.taxCode']: customer.taxItem })
				}

				/* When a new customer is chosen, we need to set
				   the document billing and shipping information to the loaded
				   customer's info as it's transactionalized per document */
				ractive.setCustomerAddress(customer, 'billing')
				ractive.setCustomerAddress(customer, 'shipping')
			})

			ractive.observe('saleDocument.customer', customer => {
				if (customer && customer.customerId) {
					mediator.call('emitToServer', 'load customer balance', { customerId: customer.customerId })
						.then(res => ractive.set({ customerBalance: res?.[0]?.balance }))
				} else {
					ractive.set({ customerBalance: 0 })
				}
			})

			//For some reason, the document lines don't recompute
			//when we call go on the state router, so force an update
			//on Ractive here.
			ractive.update()

			ractive.observe('saleDocument', saleDocument => {
				console.log(saleDocument)
				if (saleDocument && saleDocument.saveState === 'UNMODIFIED') {
					saleDocument.saveState = 'MODIFIED'
				}
			}, { init: false })

			if (!ractive.get('saleDocument.customer')) {
				const customerLookupInput = ractive.find('#customerLookupInput')

				if (customerLookupInput) {
					customerLookupInput.focus()
				}
			}

			//New Line
			ractive.on('newLineQuantityChange', (context, event, row) => {
				ractive.newDocumentLineFromNewLine({
					...row,
					quantity: domValue(event.node),
				})
			})
			ractive.on('newLineTaxChange', (context, event, row) => {
				ractive.newDocumentLineFromNewLine({
					...row,
					isTaxed: domValue(event.node) === 'on',
				})
			})
			ractive.on('newLineDescriptionChange', (context, event, row) => {
				ractive.newDocumentLineFromNewLine({
					...row,
					description: domValue(event.node),
				})
			})
			ractive.on('newLinePriceChange', (context, value, row) => {
				ractive.newDocumentLineFromNewLine({
					...row,
					price: value,
				})
			})

			//Other Lines
			ractive.on('quantityChange', (context, event, row) => {
				ractive.changeDocumentLine('quantity', domValue(event.node), row.uuid)
			})
			ractive.on('taxChange', (context, event, row) => {
				ractive.changeDocumentLine('isTaxed', domValue(event.node) === 'on', row.uuid)
			})
			ractive.on('descriptionChange', (context, event, row) => {
				ractive.changeDocumentLine('description', domValue(event.node), row.uuid)
			})
			ractive.on('priceChange', (context, value, row) => {
				ractive.changeDocumentLine('price', value, row.uuid)
			})

			ractive.on('clear', () => {
				ractive.set({ wantsDocumentCache: false }).then(() => {
					localStorage.removeItem('saleDocument')
					stateRouter.go('app.redirect', { state: 'app.sale' })
				})
			})

			ractive.on('void', async() => {
				const saleDocument = ractive.get('saleDocument')

				if (saleDocument?.documentId && saleDocument?.documentType === 'INVOICE') {
					try {
						await mediator.call('emitToServer', 'void sale', { saleId: saleDocument.documentId })
					} catch (err) {
						logAndAlert(err, mediator, 'Error voiding sale')
					}
					stateRouter.go('app.redirect', {
						state: 'app.sale',
						documentType: 'INVOICE',
						documentId: saleDocument.documentId,
					}, { replace: true })
				}
			})

			ractive.on('addDocumentLineConfirm', (context, { newSaleLine: SalesOrderDocumentLine, index }) => {
				ractive.addDocumentLine({ SalesOrderDocumentLine, index })
			})

			ractive.on('partLookup', (context, event, index) => {
				const inputValue = domValue(event.node).trim()

				if (inputValue) {
					ractive.performAddItemLookup(inputValue, null, index)
				} else {
					console.log('No tag number input value')
				}
			})

			ractive.on('vehicleLookup', async(context, event, index) => {
				const inputValue = domValue(event.node).trim()

				if (inputValue) {
					const vehicleList = await mediator.call('emitToServer', 'load vehicle', { trackingNumber: inputValue })
					if (vehicleList.length === 1) {
						const saleDocument = ractive.get('saleDocument')
						const newSaleLine = new SalesOrderDocumentLineFromVehicle({
							saleDocument,
							vehicle: vehicleList[0],
							documentType: saleDocument.documentType,
							uuid: uuid(),
							saveState: 'NEW',
						})

						ractive.addVehicle(vehicleList[0])
						ractive.addDocumentLine({
							SalesOrderDocumentLine: newSaleLine,
							index,
						})
					} else {
						console.log(`${vehicleList.length} vehicles found! I' ll just do nothing for now.`)
					}
				} else {
					console.log('no input value')
				}
			})

			ractive.on('loadDocument', async(context, { documentType, documentId }) => {
				try {
					await mediator.call('emitToServer', 'load sale document', {
						documentType,
						saleDocumentId: documentId,
					})

					stateRouter.go('app.redirect', {
						state: 'app.sale',
						documentType,
						documentId,
					}, { replace: true })
				} catch (err) {
					await ractive.set({ documentNotFound: true })
					ractive.findComponent('documentLoadModal')?.find('#documentLoadDocumentTypeSelect')?.select()
				}
			})

			ractive.observe('loadDocumentModalShown selectedLoadDocumentTypeCode loadDocumentDocumentNumber', () => {
				ractive.set({ documentNotFound: false })
			})

			ractive.on('openCustomer', () => {
				stateRouter.go('app.entity', {
					id: ractive.get('saleDocument.customer.customerId'),
					entityContext: 'customer',
				})
			})

			ractive.on('clearNewLineRow', () => ractive.clearNewLineRow())

			ractive.on('deleteLineItem', (context, lineItemToDelete) => {
				const indexToDelete = ractive.get('saleDocument.saleDocumentLines')
					.findIndex(line => line.uuid === lineItemToDelete.uuid)

				if (indexToDelete > -1) {
					if (lineItemToDelete.saleDocumentLineId) {
						ractive.splice('saleDocument.saleDocumentLines', indexToDelete, 1, {
							...lineItemToDelete,
							saveState: 'DELETE',
						})
					} else {
						ractive.splice('saleDocument.saleDocumentLines', indexToDelete, 1)
					}
				}
			})

			ractive.observe('taxedLinesTotal nonTaxedLinesTotal', () => {
				const taxedLinesTotal = ractive.get('taxedLinesTotal')
				const nonTaxedLinesTotal = ractive.get('nonTaxedLinesTotal')

				const subtotal = financialNumber(taxedLinesTotal.toString())
					.plus(nonTaxedLinesTotal.toString())
					.toString(2, financialNumber.round)

				ractive.set('saleDocument.subtotal', subtotal)
			}, { init: false })

			ractive.observe('taxedLinesTotal saleDocument.taxCode', () => {
				const taxedLinesTotal = ractive.get('taxedLinesTotal')
				const taxCode = ractive.get('saleDocument.taxCode')

				const foundTaxItem = ractive.get('taxItems')
					.find(taxItem => taxItem.name === taxCode)

				const taxRate = foundTaxItem ? foundTaxItem.rate : 0

				const tax = financialNumber(taxedLinesTotal.toString())
					.times(taxRate.toString())
					.toString(2, financialNumber.round)

				ractive.set('saleDocument.tax', tax)
			}, { init: false })

			ractive.observe('saleDocument.subtotal saleDocument.tax', () => {
				const subtotal = ractive.get('saleDocument.subtotal') || 0
				const tax = ractive.get('saleDocument.tax') || 0

				const total = financialNumber(subtotal.toString())
					.plus(tax.toString())
					.toString()

				ractive.set('saleDocument.total', total)
			}, { init: false })

			ractive.observe('rememberSaleSaveOptions printDocument printPicklist', () => {
				if (ractive.get('rememberSaleSaveOptions')) {
					localStorage.setItem('saleSaveOptions', JSON.stringify({
						printDocument: ractive.get('printDocument'),
						printPicklist: ractive.get('printPicklist'),
					}))
				} else {
					localStorage.removeItem('saleSaveOptions')
				}
			}, { init: false })

			ractive.on('adjustPrice', (context, line) => {
				const customerPriceTypes = ractive.get('customerPriceTypes')
				const customerPriceType = ractive.get('customerPriceType')
				let part
				let priceKey

				if (line?.inventoryId) {
					part = ractive.get('saleDocument.inventoryList')?.find(part => part.inventoryId === line.inventoryId)
				}

				const existingPriceKey = findValueInObject(line.price, part, customerPriceTypes.map(type => type.key))

				//look to see if the item's current price matches an existing price from the inventory record
				if (part) {
					priceKey = existingPriceKey || customerPriceType?.key
				} else {
					priceKey = customerPriceType?.key
				}

				ractive.set({
					priceAdjustmentModal: {
						show: true,
						line,
						part,
						selectedPriceTypeKey: priceKey || null, //null means custom. If they have no customer, and no part, then it's custom
						customPricePercentage: ractive.get('customerPercentOfPrice'),
						customPrice: priceKey ? part[priceKey] : line.price,
					},
				}).then(() => {
					ractive.find('#customPricePercentageInput').select()
				})
			})

			ractive.on('confirmPriceAdjustment', (context, { line }, newPrice) => {
				ractive.upsert('saleDocument.saleDocumentLines', 'uuid', { ...line, price: newPrice })
					.then(() => {
						ractive.set({ 'priceAdjustmentModal.show': false })
					})
			})

			ractive.on('restorePriceAdjustmentsToCustomerDefaults', () => {
				ractive.set({
					'priceAdjustmentModal.selectedPriceTypeKey': ractive.get('customerPriceType')?.key || 'retailPrice',
					'priceAdjustmentModal.customPricePercentage': ractive.get('customerPercentOfPrice'),
				})
			})

			ractive.on('document-type-changed', (context, saleDocument) => {
				// Only have to deal with converting Quote -> Invoice if it has been saved
				if (saleDocument.documentType === 'INVOICE' && saleDocument.saveState !== 'NEW') {
					// Strip ids so the server will assign new ones
					if (saleDocument.quoteId) {
						saleDocument.documentId = undefined
						saleDocument.saleId = undefined
					}
					// Set 'Invoice' status since quotes don't have a status
					if (saleDocument.status === null) {
						saleDocument.status = 'Invoice'
					}
					// Set the saveState to something so the server knows to update the quote
					saleDocument.saveState = 'QUOTETOINVOICE'
					// Also flag lines for Quote to Invoice conversion, this tells the server to save these as new Sale lines
					saleDocument.saleDocumentLines = saleDocument.saleDocumentLines.map(line => {
						line.saveState = line.saleDocumentLineId ? 'QUOTETOINVOICE' : 'NEW'
						return line
					})
					// ractive set so it can be fetched by the save function
					ractive.set('saleDocument', saleDocument)
				} else if (saleDocument.saveState === 'QUOTETOINVOICE') {
					// If the user switches from Quote -> Invoice -> Quote without saving, reset it back to a quote
					// Quotes don't have a status
					saleDocument.status = null
					// If the quote was previously saved, restore the quoteId, set saveState
					if (saleDocument.quoteId) {
						saleDocument.documentId = saleDocument.quoteId
						saleDocument.saveState = 'MODIFIED'
					} else {
						// If the quote was never saved, set saveState to new
						saleDocument.saveState = 'NEW'
					}
					// Clear the QUOTETOINVOICE status from the lines
					// We don't know whether they were modified or not, so if they were saved, just assume they were.
					if (saleDocument.saleDocumentLines.length && saleDocument.saleDocumentLines.some(line => line.saveState === "QUOTETOINVOICE")) {
						saleDocument.saleDocumentLines = saleDocument.saleDocumentLines.map(line => {
							if (line.saveState === "QUOTETOINVOICE") {
								line.saveState = line.saleDocumentLineId ? 'MODIFIED' : 'NEW'
							}
							return line
						})
					}
					ractive.set('saleDocument', saleDocument)
				}
			})

			ractive.on('saveConfirm', () => ractive.saveConfirm())
		},
	})
}
// Save states: NEW, UNMODIFIED, MODIFIED, QUOTETOINVOICE
