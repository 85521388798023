<script lang="ts">
	import type { PartStatus, VehicleStatus } from '../../server/db/status-db'
	import Select from '@isoftdata/svelte-select'

	type Status = PartStatus | VehicleStatus
	// type StatusOrAt = Status | '@'
	type StatusOrAt = string

	type DisplayStatus = {
		abbreviation: StatusOrAt
		name: string | null
	}

	export let displayFullName = true
	// statuses to be hidden from the dropdown
	export let filterStatuses: Array<StatusOrAt> = []
	// Whether to hide @ from the dropdown
	export let filterAt = false //@ === (A or H)
	export let showLabel = true
	export let value: StatusOrAt
	export let disabled = false
	export let statuses: Array<DisplayStatus>
	export let showEmptyOption = true

	$: displayStatuses = getDisplayStatuses(statuses, filterStatuses, filterAt)
	function getDisplayStatuses(statuses: Array<DisplayStatus>, filterStatuses: Array<StatusOrAt>, filterAt: boolean) {
		let displayStatuses = statuses

		if (statuses && filterStatuses && Array.isArray(filterStatuses) && filterStatuses.length > 0) {
			displayStatuses = statuses.reduce((acc, status) => {
				if (filterStatuses.find(filterStatus => filterStatus === status.abbreviation)) {
					return acc
				}
				return acc.concat(status)
			}, new Array<DisplayStatus>())
		}

		function statusFound(statusToMatchOn: Status) {
			if (statusToMatchOn && displayStatuses) {
				return !!displayStatuses.find(status => status.abbreviation === statusToMatchOn)
			}
			return false
		}

		if (statusFound('A') && statusFound('H') && filterStatuses && !filterStatuses.includes('@') && !filterAt) {
			displayStatuses = displayStatuses.concat([{ abbreviation: '@' as const, name: 'A or H' }])
		}

		return displayStatuses
	}
</script>

<Select
	label="Status"
	{showLabel}
	{showEmptyOption}
	disabled={!displayStatuses.length || disabled}
	options={displayStatuses}
	bind:value
	on:change
	let:option
>
	<option value={option?.abbreviation}>{displayFullName ? `${option?.abbreviation} - ${option?.name}` : option?.abbreviation}</option>
</Select>
