import makeStorageHelper from './storage-json-helper.ts'
import { v4 as uuid } from '@lukeed/uuid'

const localStorageJSON = makeStorageHelper('localStorage')

export function getErrorLog() {
	return localStorageJSON.getItem(`errorLog`) || {}
}

// Used to update either the `message` or `context` of a logged error
function concatProperty(loggedValue, newValue) {
	if (loggedValue
		&& newValue
		&& loggedValue !== newValue
		&& typeof newValue === 'string'
		&& typeof loggedValue === 'string'
	) { // concat
		return loggedValue.concat('\n ', newValue)
	} else if (!newValue) { // no new value, just use the old one
		return loggedValue
	} else { // no old value, take new one
		return newValue
	}
}

function logError(err, context, message) {
	const { userAccountId } = localStorageJSON.getItem('session') || { userAccountId: 'unknown' }
	const errorLog = getErrorLog()

	let newErrorLogEntry = {
		error: err,
		context, // i.e. a state or endpoint name
		timestamp: new Date().toISOString(),
		message,
	}

	if (!(errorLog[userAccountId] instanceof Array)) {
		errorLog[userAccountId] = []
	}

	if (err.logged && err.uuid) { // if error has already been logged, update message/context on existing entry
		const loggedErrorIndex = errorLog[userAccountId].findIndex(loggedError => err?.uuid === loggedError?.error?.uuid)
		const loggedError = errorLog[userAccountId][loggedErrorIndex] || {}
		// update message
		newErrorLogEntry.message = concatProperty(loggedError.message, message)
		// update context
		newErrorLogEntry.context = concatProperty(loggedError.context, context)
		// reinsert into error log
		errorLog[userAccountId][loggedErrorIndex] = newErrorLogEntry
	} else if (!err.logged) { // if this error hasn't been logged, add it to the front of the log
		errorLog[userAccountId].unshift(newErrorLogEntry)
	}

	// This is entirely arbitrary but I figure we want some sort of log rotation
	if (errorLog[userAccountId].length > 100) {
		errorLog[userAccountId].pop()
	}

	localStorageJSON.setItem(`errorLog`, errorLog)
}

function makeErrorMessage(err, message) {
	// Will use the passed message if provided, unless it's a validation error.
	if (err?.name === 'ValidationError') { // Yup Validation Error
		message = 'Error: Failed to save due to the following errors: \n\n'.concat(err.errors.join(',\n\n'))
	} else if (!message && err?.sql) { // SQL error
		message = 'Error: A query error has occurred when loading or saving data. Contact ISoft Support at 1-800-929-1829'
	} else if (!message && err.error === 'invalid_grant') {
		message = 'Error: QuickBooks Online authentication error. If this persists, try disconnecting from and reconnecting to QuickBooks Online.'
	} else if (!message && err.intuitObject !== undefined) {
		message = 'Error: A QuickBooks Online error has occurred. If this persists, try disconnecting from and reconnecting to QuickBooks Online.'
	} else if (!message && err.userFriendlyMessage) { // Report error
		message = err.userFriendlyMessage
	} else if (!message && err?.message) { // JS error class
		message = err.message
	} else if (!message) { // Other error / No message provided
		message = 'Error: An unknown error has occurred.'
	}
	return message
}

function alertError(err, mediator, showMessageOptions = {}) {
	try {
		mediator.call('showMessage', { type: 'danger', time: false, ...showMessageOptions, message: err.message })
	} catch (showMessageErr) {
		// If the mediator is not available, or the function isn't provided, just fallback to a JS alert
		if (showMessageErr?.message?.includes('(reading \'call\')') || showMessageErr?.message?.includes('No provider found for "showMessage"')) {
			alert(err.message)
		} else { // If calling 'showMessage' gives us an error we don't expect, make sure to alert that as well.
			logError(showMessageErr)
			alert(makeErrorMessage(showMessageErr))
		}
	}
}
/**
 * Alerts the user to an error and logs it if it is the first time this function has been called for that error.
 * Special handling exists for Yup ValidationErrors and mysql errors.
 * @param {error} err An Error object.
 * @param {object=} mediator The mediator. Called to create an alert. If excluded, just does a vanilla JS alert.
 * @param {string=} [message=''] If truthy, will be shown to the user instead of the default message for the error type, unless it's a Yup ValidationError.
 * @param {*} context Anything extra you want logged alongside the error. I.e. an endpoint name and parameters.
 * @param {*} showMessageOptions Optional Options to be passed to `showMessage`. If explicitly `false`, the alert will not be shown.
 * @returns {error} The Error object, with the property`logged: true` added to it.
 */
export function logAndAlert(err, mediator, message = '', context = '', showMessageOptions = {}) {
	console.log(err instanceof Error)
	console.trace()
	// Make sure that the error is an object before assigning properties to it
	if (typeof err !== 'object') {
		err = {
			original: err,
		}
	}
	err.message = makeErrorMessage(err, message)

	// Put a uuid on the error so that we can find it in the log if logAndAlert is called on the same error again
	if (err && !err.uuid) {
		err.uuid = uuid()
	}

	if (err) {
		logError(err, context, message)
		err.logged = true
	}
	// If `showMessageOptions` is explicitly false, do not alert on the error so it can be handled elsewhere without double alerting
	if (err && !err.alerted && showMessageOptions !== false) {
		alertError(err, mediator, showMessageOptions)
		err.alerted = true
	}
	return err
}
