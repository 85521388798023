<script lang="ts">
	// importing this will give you an error if you delete this script tag so don't do that
</script>

<div class="card bg-light">
	<div class="card-body">
		<h2 class="card-title text-center">
			No <slot /> for Provided Filter
		</h2>
		<p class="card-text text-center">Try changing your filter</p>
	</div>
</div>
