import template from './user-auth.html'

//Ractive components
import makeInput from '@isoftdata/input'

export default function(_mediator) {
	return Ractive.extend({
		template,
		isolated: true,
		components: {
			itInput: makeInput({ twoway: true, lazy: false }),
		},
		data() {
			return {
				username: '',
				password: '',
				permissionCode: '',
			}
		},
	})
}
