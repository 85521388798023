import template from './user-permission-auth-modal.html'

//Ractive components
import makeUserAuth from 'components/user-auth'
import makeModal from '@isoftdata/modal'

export default function(mediator) {
	return Ractive.extend({
		template,
		components: {
			itModal: makeModal(),
			userAuth: makeUserAuth(mediator),
		},
		data() {
			return {
				username: '',
				password: '',
				permissionCode: '',
				permissionName: '',
				show: false,
			}
		},
		oninit() {
			const ractive = this

			ractive.observe('show', show => {
				if (show) {
					mediator.call('emitToServer', 'load permissions', {
						code: ractive.get('permissionCode'),
					})
						.then(permissions => {
							if (permissions[0]) {
								ractive.set({ permissionName: permissions[0].name })
							}
						})
				} else {
					ractive.set({ permissionName: '' })
				}
			}, { init: false })
		},
		authCheck() {
			// eslint-disable-next-line no-unused-vars
			const ractive = this

			/*ractive.findComponent('userAuth').hasPermission((err, hasPermission) => {
				if (hasPermission) {

				}
			})*/
		},
	})
}
