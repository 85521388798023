<script lang="ts">
	import type { PartManufacturer } from '../../server/db/part-manufacturer-db'
	import type { Mediator } from 'types/common'

	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'

	import klona from 'klona'
	import { createEventDispatcher, getContext, tick } from 'svelte'
	import { logAndAlert } from 'utility/error-handler'

	export let shown = false

	let isSaving = false
	let manufacturer: Partial<PartManufacturer> = {}

	const newManufacturer = {
		name: '',
		partManufacturerId: undefined,
	} as const
	const mediator = getContext<Mediator>('mediator')
	const dispatch = createEventDispatcher<{
		manufacturerSaved: { manufacturer: PartManufacturer }
	}>()

	export async function show(man?: Partial<PartManufacturer>) {
		if (!man) {
			manufacturer = klona(newManufacturer)
		}
		shown = true
		await tick()
		document.getElementById('partManufacturerModalInput')?.focus()
	}

	async function save() {
		isSaving

		try {
			const savedManufacturer = (await mediator.call('emitToServer', 'save part manufacturer', { manufacturer }, { doNotAlert: true })) as PartManufacturer
			shown = false
			dispatch('manufacturerSaved', { manufacturer: savedManufacturer })
		} catch (err) {
			if (err instanceof Object && 'code' in err && err.code === 'ER_DUP_ENTRY') {
				// If they give a duplicate name, add that to the manu list so they can add a model
				const loadedManufacturer = (await mediator.call('emitToServer', 'load part manufacturer by name', { name: manufacturer.name })) as PartManufacturer
				shown = false
				dispatch('manufacturerSaved', { manufacturer: loadedManufacturer })
			} else {
				logAndAlert(err, mediator)
			}
		} finally {
			isSaving = false
		}
	}
</script>

<Modal
	bind:show={shown}
	title="{manufacturer.partManufacturerId ? 'Edit' : 'Add'} Manufacturer"
	modalSize="sm"
	confirmButtonText="Save"
	savingInProgress={isSaving}
	confirmButtonDisabled={!manufacturer.name}
	confirmButtonType="submit"
	confirmButtonFormId="partManufacturerModallForm"
	on:confirm={() => save()}
	on:close={() => (shown = false)}
>
	<form
		id="partManufacturerModalForm"
		on:submit|preventDefault={() => save()}
	>
		<Input
			id="partManufacturerModalInput"
			label="Manufacturer"
			value={manufacturer.name ?? ''}
			autocomplete="off"
			maxlength={25}
			on:input={event => event.target instanceof HTMLInputElement && (manufacturer.name = event.target.value)}
		></Input>
	</form>
</Modal>
