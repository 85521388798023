<script lang="ts">
	import Select from '@isoftdata/svelte-select'

	type Store = {
		storeId: number
		name: string
	}

	// TODO, session svelte store?
	const session = JSON.parse(localStorage.getItem('session') ?? '{}')

	export let storeList: Array<Store> = []
	export let selectedStoreId: number | null = null
	export let parentDivClass = ''
	export let showAllOption = true
	export let disabled = false

	let currentStore: Store = {
		storeId: parseInt(session.storeId, 10),
		name: session.store.name,
	}

	$: displayStores = currentStore?.storeId ? storeList.filter(store => store.storeId !== currentStore.storeId) : storeList
</script>

<Select
	label="Store"
	{parentDivClass}
	{disabled}
	showEmptyOption={showAllOption}
	emptyValue={null}
	emptyText="All Stores"
	bind:value={selectedStoreId}
>
	{#if currentStore}
		<optgroup label="Current Store">
			<option value={currentStore.storeId}>{currentStore.storeId} - {currentStore.name}</option>
		</optgroup>
		<optgroup label="Other Stores">
			{#each displayStores as store}
				<option value={store.storeId}>{store.storeId} - {store.name}</option>
			{/each}
		</optgroup>
	{:else}
		{#each displayStores as store}
			<option value={store.storeId}>{store.storeId} - {store.name}</option>
		{/each}
	{/if}
</Select>
