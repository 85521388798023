<script
	context="module"
	lang="ts"
>
	import type { Payment, Sale, PaymentMethod, DisplayPayment, Customer, PaymentTreeNode } from 'utility/payment-helper'
	import type { Mediator, SvelteAsr } from 'types/common'
	import type { Column } from '@isoftdata/svelte-table'

	//@ts-ignore
	import financialNumber from 'financial-number'
	import { logAndAlert } from 'utility/error-handler'
	import { getContext, onMount } from 'svelte'
	import { Table, Td, TreeRow } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import AddEditPaymentModal from './AddEditPaymentModal.svelte'
	import ReportJobModal from 'components/ReportJobModal.svelte'
	import Icon from '@isoftdata/svelte-icon'
	import Alert from '@isoftdata/svelte-alert'
	import * as currency from '@isoftdata/utility-currency'
	import { toLocaleDateString } from '@isoftdata/utility-date-time'
	import { computePaymentsWithVirtualParents } from 'utility/payment-helper'

	//End of context section
</script>

<script lang="ts">
	const formatCurrency = currency.format
	const mediator: Mediator = getContext('mediator')

	export let asr: SvelteAsr
	export let sales: Sale[] = []
	export { customer as entity }
	export let payments: Payment[] = []
	export let showApplied: boolean = false
	export let selectedSaleIds: number[] = []
	export let showVoidModal: boolean = false
	export let customerId: number | null = null
	export let showTutorialLink: boolean = true
	export let initialCustomerBalance: string = '0'
	export let paymentMethods: PaymentMethod[] = []
	export let hasAddEditPermission: boolean = false
	export let selectedPaymentId: number | null = null
	export let selectedPayment: PaymentTreeNode | undefined = undefined

	let customer: Customer
	let displaySales: Sale[] = []
	let unbalancedSales: Sale[] = []
	let reportJobModal: ReportJobModal
	let paymentTable: Table<PaymentTreeNode>
	let addEditPaymentModal: AddEditPaymentModal
	let selectionsAreApplicable: boolean = false
	let selectedPaymentsAppliedSaleIds: number[]
	let selectedPaymentLines: DisplayPayment[] = []
	let printReceiptEmailSuggestions: string[] = []
	const saleTableColumns: Column[] = [
		{ property: 'saleId', name: 'Sale #', numeric: true },
		{ property: 'documentDate', name: 'Date' },
		{ property: 'salesperson', name: 'Sales Person' },
		{ property: 'total', name: 'Total', numeric: true },
		{ property: 'balance', name: 'Current Balance', numeric: true },
	]

	function loadPayments(customerId: number, showVoid: boolean = false): Promise<Payment[]> {
		return mediator.call('emitToServer', 'load payments', { customerId, status: showVoid ? ['payment', 'void'] : 'payment' }) as Promise<Payment[]>
	}

	async function confirmVoidPayment(paymentId: number | null) {
		try {
			if (customerId && paymentId) {
				await mediator.call('emitToServer', 'void payment', { paymentId })
				const loadedPayments = await loadPayments(customerId, false)

				payments = loadedPayments
				showVoidModal = false
				mediator.call('paymentsChanged') //this will update the customer balance displayed on the parent state
			}
		} catch (err) {
			logAndAlert(err, mediator, 'Error voiding payment')
		}
	}

	function printPayment(paymentId: number | null) {
		if (paymentId) {
			reportJobModal.open(
				{
					type: 'Payment Receipt',
					parameters: { paymentnum: paymentId.toString() },
				},
				{
					emails: printReceiptEmailSuggestions,
				},
			)
		}
	}

	function toggledSelectAllSales() {
		selectedSaleIds = selectedSaleIds.length === unbalancedSales.length ? [] : unbalancedSales.map(sale => sale.saleId)
	}

	function getPaymentRowClass(payment: PaymentTreeNode, selectedPaymentId: number | null): string {
		let classes = ['cursor-pointer']
		if (payment.paymentId === selectedPaymentId) {
			classes.push('table-info')
			//We don't currently support displaying void payments in the payment table, but if we did, we'd want them syled a little different
			if (payment.status === 'Void') {
				classes.push('VoidedDocumentSelectedTableRow')
			}
		} else if (payment.status === 'Void') {
			classes.push('VoidedDocumentTableRow')
		}
		return classes.join(' ')
	}

	const reactiveStatements = {
		selectedPayment(payments: PaymentTreeNode[], selectedPaymentId: number | null): PaymentTreeNode | undefined {
			return payments.find(payment => selectedPaymentId === payment.paymentId)
		},
		paymentTableColumns(showApplied: boolean): Column[] {
			let headers: Column[] = [
				{ property: 'paymentId', name: 'Payment #' },
				{ property: 'documentDate', name: 'Date' },
				{ property: 'paymentMethodName', name: 'Method' },
				{ property: 'amount', name: 'Amount', numeric: true },
				{ property: 'comments', name: 'Comments' },
			]

			if (showApplied) {
				headers.splice(1, 0, { property: 'saleId', name: 'Sale #', numeric: true })
			}

			return headers
		},
		sumOfSelectedSales(sales: Sale[], selectedSaleIds: number[]): string {
			return sales.reduce((acc, sale) => {
				if (selectedSaleIds.includes(sale.saleId)) {
					return financialNumber(acc).plus(sale.balance.toString()).toString()
				} else return acc
			}, '0')
		},
		getSelectionsAreApplicable(selectedPayment: PaymentTreeNode | undefined, sumOfSelectedSales: string) {
			return !!(selectedPayment && sumOfSelectedSales && selectedPayment.amount === sumOfSelectedSales)
		},
		getAnyAreApplicable(sumOfSelectedSales: string, paymentsWithVirtualParents: PaymentTreeNode[]) {
			return !financialNumber(sumOfSelectedSales).equal('0') && paymentsWithVirtualParents.some(payment => financialNumber(payment.amount).equal(sumOfSelectedSales))
		},
		async saveTutorialLinkUserPreference(showTutorialLink: boolean) {
			await mediator.call('emitToServer', 'save user setting', {
				category: 'Options',
				name: 'Help -> Customer Payment Tutorial',
				type: 'Interface History',
				value: showTutorialLink,
			})
		},
		getDisplaySales(sales: Sale[], showApplied: boolean): Sale[] {
			return showApplied ? sales : sales.filter(sale => financialNumber(sale.balance).gt('0'))
		},
	}

	$: selectedPaymentsAppliedSaleIds = selectedPayment ? [selectedPayment.saleId, ...selectedPaymentLines.map(payment => payment.saleId)].filter((n): n is number => !!n) : []
	$: selectionsAreApplicable = reactiveStatements.getSelectionsAreApplicable(selectedPayment, sumOfSelectedSales)
	$: anyAreApplicable = reactiveStatements.getAnyAreApplicable(sumOfSelectedSales, paymentsWithVirtualParents)
	$: selectedPayment = reactiveStatements.selectedPayment(paymentsWithVirtualParents, selectedPaymentId)
	$: sumOfSelectedSales = reactiveStatements.sumOfSelectedSales(displaySales, selectedSaleIds)
	$: paymentTableColumns = reactiveStatements.paymentTableColumns(showApplied)
	$: paymentsWithVirtualParents = computePaymentsWithVirtualParents(payments)
	$: displaySales = reactiveStatements.getDisplaySales(sales, showApplied)
	$: reactiveStatements.saveTutorialLinkUserPreference(showTutorialLink)
	$: selectedPaymentLines = selectedPayment?.paymentLines ?? []
	$: unbalancedSales = displaySales.filter(sale => sale.balance !== 0)
	$: printReceiptEmailSuggestions = customer.emailAddress ? [customer.emailAddress] : []

	onMount(() => {
		if (selectedPaymentId) {
			document.getElementById(`paymentTableRow${selectedPaymentId}`)?.scrollIntoView()

			const uuid = paymentsWithVirtualParents.find(payment => payment.paymentId === selectedPaymentId)?.uuid
			if (uuid) {
				paymentTable.expandRow(uuid)
			}
		}
	})
</script>

{#if showTutorialLink}
	<div class="row">
		<div class="col-12 col-xl-7">
			<Alert
				color="info"
				bind:shown={showTutorialLink}
				dismissable
			>
				<Icon icon="circle-info" /> New to managing customer payments?
				<a
					href="https://youtu.be/YoWL_6Oxk54"
					target="youtube_payments_video">Check out the video tutorial</a
				>.
			</Alert>
		</div>
	</div>
{:else}
	<div
		class="d-flex justify-content-end mb-1"
		style="font-size:small;"
	>
		<a
			href="https://youtu.be/YoWL_6Oxk54"
			target="youtube_payments_video"><Icon icon="circle-info" /> Video Tutorial</a
		>
	</div>
{/if}

<div class="card-group mh-60vh">
	<div class="card">
		<div class="card-header">
			<div class="row justify-content-between">
				<h3 class="col-auto">Payments</h3>
				<div class="col-auto">
					<Checkbox
						label="Show Applied"
						bind:checked={showApplied}
						on:change={() => asr.go(null, { showApplied, saleIds: selectedSaleIds, paymentId: selectedPaymentId }, { inherit: true })}
					/>
				</div>
			</div>
		</div>
		<div class="h-100">
			{#if paymentsWithVirtualParents?.length}
				<Table
					tree
					rows={paymentsWithVirtualParents}
					columns={paymentTableColumns}
					responsive
					parentClass="mh-60vh"
					let:row
					bind:this={paymentTable}
					selectionMode="SINGLE"
					multiSelectEnabled={false}
				>
					<TreeRow
						idProp="uuid"
						parentIdProp="parentUuid"
						property="paymentId"
						node={row}
						let:node
						on:rowClick={e => {
							const clickedPayment = e.detail.parent || e.detail //if they click a child in a group, always select the parent
							selectedPaymentId = selectedPaymentId === clickedPayment.paymentId ? null : clickedPayment.paymentId
							asr.go(null, { paymentId: row.paymentId }, { inherit: true })
						}}
						class={getPaymentRowClass(row, selectedPaymentId)}
					>
						<svelte:fragment
							slot="first"
							let:node
						>
							{#if node.children.length}
								<span style="vertical-align: middle;">Group</span>
							{/if}
							{#if !node.parent && !financialNumber(sumOfSelectedSales).equal('0') && financialNumber(node.amount).equal(sumOfSelectedSales)}
								<span
									class="badge badge-success"
									title="This Payment matches the told of the selected sales and can be applied"
								>
									<Icon icon="chart-network" /> Applicable
								</span>
							{/if}
						</svelte:fragment>
						{#if showApplied}
							<Td property="saleId">
								{#if node.saleId}
									<a
										href={asr.makePath(
											'app.sale',
											{
												documentType: 'INVOICE',
												documentId: node.saleId,
											},
											{ inherit: true },
										)}>{!node.saleId || node.saleId === 0 ? '' : node.saleId}</a
									>
								{:else}
									<span class="font-italic text-muted">Unapplied</span>
								{/if}
							</Td>
						{/if}
						<Td property="documentDate">{node.documentDate ? toLocaleDateString(node.documentDate) : ''}</Td>
						<Td property="paymentMethodName">{node.paymentMethodName}</Td>
						<Td
							property="amount"
							class={node.parentUuid ? '' : 'font-weight-bold'}>{formatCurrency(node.amount)}</Td
						>
						<Td property="comments">{node.comments}</Td>
					</TreeRow>
				</Table>
			{:else}
				<div class="jumbotron jumbotron-fluid mb-0 h-100">
					<div class="container">
						<h3>No Payments</h3>
						<p class="lead">
							Customer has no{showApplied ? '' : ' unapplied'} payments.
							<br />
							Click "New Payment..." below to get started.
						</p>
					</div>
				</div>
			{/if}
		</div>
		<div class="card-footer">
			<div class="d-flex justify-content-between">
				<div class="btn-group">
					<Button
						size="sm"
						color="success"
						iconClass="plus"
						on:click={() => addEditPaymentModal.newPayment()}
					>
						New<span class="d-none d-md-inline">&nbsp;Payment</span>...
					</Button>
					<Button
						size="sm"
						color="primary"
						outline
						disabled={!selectedPaymentId || selectedPayment?.status === 'Void'}
						iconClass="pencil"
						on:click={() => {
							if (selectedPayment) {
								addEditPaymentModal.editPayment(selectedPayment)
							}
						}}
					>
						Edit...
					</Button>
					<Button
						size="sm"
						color="danger"
						outline
						disabled={!selectedPaymentId || !hasAddEditPermission || selectedPayment?.status === 'Void'}
						on:click={() => (showVoidModal = true)}
						iconClass="minus-circle">Void...</Button
					>
				</div>
				<div>
					<Button
						color="primary"
						size="sm"
						outline
						on:click={() => printPayment(selectedPaymentId)}
						disabled={!selectedPaymentId}
						iconClass="print">Print<span class="d-none d-md-inline-block">&nbsp;Receipt</span>...</Button
					>
				</div>
			</div>
		</div>
	</div>
	<div class="card">
		<div class="card-header d-flex justify-content-between">
			<div class="h3">
				{#if !showApplied}Unbalanced{/if} Sales
			</div>
			<div>
				<Button
					size="sm"
					outline
					style="width: 120px;"
					on:click={toggledSelectAllSales}
					iconClass="check-double"
					disabled={unbalancedSales.length === 0}
				>
					Select {#if unbalancedSales.length === selectedSaleIds.length}None{:else}All{/if}
				</Button>
			</div>
		</div>

		<div class="h-100">
			{#if displaySales?.length > 0}
				<Table
					rows={displaySales}
					columns={saleTableColumns}
					responsive
					parentClass="mh-60vh"
				>
					<svelte:fragment
						slot="body"
						let:rows
					>
						{#each rows as sale (sale.saleId)}
							{@const balanceIsZero = financialNumber(sale.balance.toString()).equal('0')}
							<tr
								on:click={() => {
									if (!balanceIsZero) {
										let newSelectedSaleIds = selectedSaleIds
										if (selectedSaleIds.includes(sale.saleId)) {
											newSelectedSaleIds = newSelectedSaleIds.filter(id => id !== sale.saleId)
										} else {
											newSelectedSaleIds.push(sale.saleId)
										}
										selectedSaleIds = newSelectedSaleIds
									}
								}}
								class:cursor-pointer={!balanceIsZero}
								class:table-info={selectedSaleIds.includes(sale.saleId)}
								class:table-secondary={balanceIsZero}
							>
								<Td property="saleId">
									{#if selectedPaymentsAppliedSaleIds.includes(sale.saleId)}
										<Icon
											icon="chart-network"
											class="text-success"
											title="The selected payment is applied to this sale."
										/>
									{/if}
									{sale.saleId}
								</Td>
								<Td property="documentDate">{sale.documentDate ? toLocaleDateString(sale.documentDate) : ''}</Td>
								<Td property="salesperson">{sale.salesperson ?? ''}</Td>
								<Td property="total">{formatCurrency(sale.total)}</Td>
								<Td
									property="balance"
									class="font-weight-bold"
								>
									{formatCurrency(sale.balance)}</Td
								>
							</tr>
						{/each}
					</svelte:fragment>
				</Table>
			{/if}
			{#if displaySales?.length === 0}
				<div class="jumbotron jumbotron-fluid mb-0 h-100">
					<div class="container">
						<h3>No Unbalanced Sales</h3>
						<p class="lead">🥳 Customer has no remaining unbalanced sales!</p>
					</div>
				</div>
			{/if}
		</div>
		<div class="card-footer">
			<div>
				<div class="btn-group btn-group-sm">
					<Button
						color="success"
						size="sm"
						disabled={selectedSaleIds?.length === 0}
						iconClass="plus"
						on:click={() => addEditPaymentModal.newPaymentFromSales(selectedSaleIds, anyAreApplicable)}
					>
						New<span class="d-none d-md-inline">&nbsp;{formatCurrency(sumOfSelectedSales)} Payment</span> From Selection...
					</Button>
					<Button
						color="success"
						outline
						size="sm"
						disabled={!selectionsAreApplicable}
						on:click={() => {
							if (selectedPayment) {
								addEditPaymentModal.applyPayment(selectedPayment, selectedSaleIds)
							}
						}}
						iconClass="chart-network"
					>
						Apply<span class="d-none d-md-inline">&nbsp;From Selection</span>...
					</Button>
				</div>
			</div>
		</div>
	</div>
</div>

<Modal
	show={showVoidModal}
	title="Void Payment #{selectedPaymentId}"
	cancelButtonText="Cancel"
	confirmButtonText="Void Payment #{selectedPaymentId}"
	confirmButtonColor="danger"
	on:close={() => (showVoidModal = false)}
	on:confirm={() => confirmVoidPayment(selectedPaymentId)}
>
	This will void the payment and affect the customer's balance.
</Modal>

<AddEditPaymentModal
	{paymentMethods}
	{sales}
	{customer}
	bind:this={addEditPaymentModal}
	on:paymentSaved={() => {
		mediator.call('paymentsChanged')
		selectedSaleIds = [] //The sales they had selected before are likely now balance or no longer valid selections, so clear them
		asr.go(null, { lastModified: new Date()?.getTime() }, { inherit: true })
		//this asr.go will reload the state, getting us the new customer balance
	}}
	{initialCustomerBalance}
/>

<ReportJobModal bind:this={reportJobModal} />
