<script lang="ts">
	import type { ComponentProps } from 'svelte'
	import type { Inventory } from 'server/db/inventory-db.js'
	import type { InventoryType } from 'server/db/inventory-type-db'
	import type { AppContext, Mediator, SvelteAsr } from 'types/common'
	import type { PartProviders } from './part'

	import ReportJobModal from 'components/ReportJobModal.svelte'
	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
	import LoadItemModal from '@isoftdata/svelte-load-item-modal'
	import Modal from '@isoftdata/svelte-modal'
	import Button from '@isoftdata/svelte-button'
	import Icon from '@isoftdata/svelte-icon'

	import { getContext, onDestroy, onMount } from 'svelte'
	import attachmentToFileObject from 'utility/attachment-to-File-object'
	import webShareAllowedMimeTypes from 'utility/web-share-allowed-mime-types'
	import { logAndAlert } from 'utility/error-handler'

	export let asr: SvelteAsr
	export let inventoryTypes: Array<InventoryType>
	export let part: Inventory | undefined

	export let unshareableFilesModal: {
		show?: boolean
		unshareableFiles?: Array<unknown>
		share?: ShareData
	} = {
		show: false,
		unshareableFiles: [],
	}
	export let showPartSearchResultsControls: boolean
	export let partSearchResults: Array<number>
	export let stateName: string
	export let inventoryId: number

	let reportJobModal: ReportJobModal
	let loadPartModal: LoadItemModal<{ inventoryId: number; tagNumber: string }>
	let isLoadingShareInfo = false

	type ShareData = globalThis.ShareData

	const mediator = getContext<Mediator<PartProviders>>('mediator')
	const hasPermission = getContext<AppContext['hasPermission']>('hasPermission')
	const isChildState = getContext<AppContext['isChildState']>('isChildState')

	const currentPartSearchResultIndex = partSearchResults?.findIndex(partSearchResultInventoryId => partSearchResultInventoryId === inventoryId)
	const previousPartSearchResultIndex = currentPartSearchResultIndex > -1 && currentPartSearchResultIndex > 0 ? currentPartSearchResultIndex - 1 : null
	const nextPartSearchResultIndex = currentPartSearchResultIndex > -1 && currentPartSearchResultIndex + 1 < partSearchResults.length ? currentPartSearchResultIndex + 1 : null
	const canEditPart = hasPermission('PM_PART_EDIT')
	const supportsWebShare = !!navigator.share
	const tabs: ComponentProps<AsrNavTabBar>['tabs'] = [
		{
			title: 'Basic',
			name: 'app.part.basic',
			inherit: true,
		},
		{
			title: 'Attachments',
			name: 'app.part.attachment',
			inherit: true,
			disabled: !hasPermission('PM_PART_IMAGE_ATTACHMENT_VIEW'),
		},
		{
			title: 'History',
			name: 'app.part.history',
			inherit: true,
		},
		{
			title: 'Breakdown',
			name: 'app.part.breakdown',
			inherit: true,
		},
	]

	$: inventoryTypeName = inventoryTypes.find(({ inventoryTypeId }) => inventoryTypeId === part?.inventoryTypeId)?.name ?? ''
	$: partTitle = {
		title: part?.tagNumber || part?.inventoryId || 'New Part',
		subtitle: [inventoryTypeName, part?.partManufacturer, part?.partModel].filter(item => item).join(' '),
	}
	const partSearchResultsPreviousInventoryId = typeof previousPartSearchResultIndex === 'number' ? partSearchResults[previousPartSearchResultIndex] : null
	const partSearchResultsNextInventoryId = typeof nextPartSearchResultIndex === 'number' ? partSearchResults[nextPartSearchResultIndex] : null

	// todo narrow type of `type` here to just valid options
	function sendToDocument(inventoryId: number, type: `app.${'sale' | 'purchase-order'}`) {
		sessionStorage.setItem(`pending-${type}-item-add`, JSON.stringify({ inventoryIds: [inventoryId] }))
		asr.go(type)
	}

	function printTag() {
		if (part?.inventoryId && part?.storeId) {
			reportJobModal.open({
				type: 'Tags',
				parameters: {
					//TODO: this report should support taking a tuple of IDs instead of this screen having to provide an entire WHERE clause
					tagnums: `inventory.partnum = ${part.inventoryId}`,
					store: part.storeId.toString(),
				},
			})
		} else {
			throw new Error('Error printing tag report')
		}
	}

	async function share() {
		if (!supportsWebShare) {
			return alert('Sharing is not supported by your browser. Please try a different browser.')
		}
		isLoadingShareInfo = true
		const fileList = (await mediator.call('emitToServer', 'load file info list', {
			association: {
				type: 'INVENTORY',
				key: {
					inventoryId: part?.inventoryId,
				},
			},
			isPublic: true,
		})) as Array<any>

		let files = await Promise.all(
			fileList.map(fileMeta => {
				return attachmentToFileObject(fileMeta.path, fileMeta.name, fileMeta.mimeType)
			}),
		)

		const sharePart = {
			...part,
			inventoryTypeName: inventoryTypeName,
		}

		const title = [sharePart.inventoryTypeName, sharePart.partManufacturer, sharePart.partModel].filter(item => item).join(' ')

		const includeKeysMapping = {
			inventoryTypeName: 'Part Type',
			partManufacturer: 'Part Manufacturer',
			partModel: 'Part Model',
			vin: 'VIN',
			make: 'Vehicle Make',
			model: 'Vehicle Model',
			year: 'Year',
			quantity: 'Quantity',
			retailPrice: 'Retail Price',
			category: 'Category',
			condition: 'Condition',
			side: 'Side',
			oemNumber: 'OEM Number',
			upc: 'UPC',
		} as const

		const text = Object.keys(includeKeysMapping)
			.reduce((sum, key) => {
				return sharePart[key as keyof typeof sharePart] ? [...sum, `${includeKeysMapping[key as keyof typeof includeKeysMapping]}: ${sharePart[key as keyof typeof sharePart]}`] : sum
			}, new Array<string>())
			.join('\n')

		const filesToShare = files.filter(file => webShareAllowedMimeTypes.includes(file.type))

		if (filesToShare.length === files.length) {
			confirmShare({ title, text, files: filesToShare.length ? filesToShare : [] })
		} else {
			const filesThatCanNotBeShared = files.filter(file => !webShareAllowedMimeTypes.includes(file.type)).map(file => file.name)
			unshareableFilesModal = {
				show: true,
				unshareableFiles: filesThatCanNotBeShared,
				share: { title, text, files: filesToShare.length ? filesToShare : [] },
			}
		}
		isLoadingShareInfo = false
	}

	function confirmShare(shareData: ShareData) {
		try {
			navigator.share(shareData)
		} finally {
			unshareableFilesModal.show = false
		}
	}

	function doTagNumberSearch(searchString: string) {
		return mediator.call('emitToServer', 'part tag number lookup', { searchString }) as Promise<{ inventoryId: number; tagNumber: string }[]>
	}

	const removePartSaveProvider = mediator.provide('partSave', ({ stateThatSaved, data }: { stateThatSaved: string; data: typeof part }) => {
		if (
			isChildState({
				childState: stateThatSaved,
				parentState: stateName,
			}) &&
			data
		) {
			console.log('part saved', data)
			part = data
		}
	})

	onMount(() => {
		window.scrollTo(0, 0)

		if (part?.inventoryId) {
			// Seems to be some race condition between this state and the app state, where sometimes this gets called before the mediator provider is initialized
			// I think app state's activate fires after this component's onMount due to lifecycle differences
			setTimeout(() => {
				mediator.call('activity', {
					stateName,
					stateParameters: {
						inventoryId: part?.inventoryId,
						storeId: part?.storeId,
					},
					stateCategory: 'PART',
					action: 'VIEW',
					displayTitle: `Part # ${part?.tagNumber || part?.inventoryId}`,
					stateParameterKey: 'inventoryId',
				})
			}, 1000)
		}
	})

	onDestroy(() => {
		removePartSaveProvider?.()
	})
</script>

<div class="card mb-3">
	<div class="card-header">
		<div class="d-sm-flex">
			<div class="mr-auto">
				<h4 class="inline">
					<Icon icon="engine" />
					{#if partTitle}
						<span class="d-inline-block">
							{partTitle.title}
							{#if partTitle.subtitle}
								<small>({partTitle.subtitle})</small>{/if}
						</span>
					{/if}
				</h4>
			</div>

			<div>
				{#if canEditPart}
					<Button
						size="sm"
						color="success"
						class="mb-1 mb-sm-0"
						iconClass="plus"
						href={asr.makePath('app.redirect', { state: 'app.part' })}
						>New<span class="d-none d-sm-inline-block">&nbsp;Part</span>
					</Button>
				{/if}

				<Button
					size="sm"
					color="primary"
					class="mb-1 mb-sm-0"
					iconClass="print"
					on:click={printTag}>Tag</Button
				>

				<Button
					outline
					size="sm"
					color="success"
					iconClass="dollar-sign"
					class="mb-1 mb-sm-0"
					title="Add this part to a sale or quote."
					disabled={!part?.inventoryId}
					on:click={() => part && sendToDocument(part.inventoryId, 'app.sale')}>Sell</Button
				>

				{#if part?.replenish}
					<Button
						outline
						size="sm"
						color="success"
						iconClass="box-open"
						class="mb-1 mb-sm-0"
						title="Add this (replenishable) part to a purchase order."
						disabled={!part?.inventoryId}
						on:click={() => part && sendToDocument(part.inventoryId, 'app.purchase-order')}>Buy</Button
					>
				{/if}

				<Button
					outline
					size="sm"
					class="mb-1 mb-sm-0"
					iconClass="share-alt"
					disabled={!supportsWebShare}
					isLoading={isLoadingShareInfo}
					on:click={share}>Share</Button
				>

				<Button
					size="sm"
					class="mb-1 mb-sm-0"
					iconClass="search"
					on:click={() => loadPartModal.show()}
				/>

				{#if showPartSearchResultsControls}
					<Button
						size="sm"
						class="mt-1 mb-1"
						iconClass="caret-left"
						title="Show Previous Part In Search Results"
						disabled={previousPartSearchResultIndex === null || !partSearchResultsPreviousInventoryId}
						href={asr.makePath(null, { inventoryId: partSearchResultsPreviousInventoryId }, { inherit: true })}
					/>

					<Button
						size="sm"
						class="mt-1 mb-1"
						iconClass="caret-right"
						title="Show Next Part In Search Results"
						disabled={nextPartSearchResultIndex === null || !partSearchResultsNextInventoryId}
						href={asr.makePath(null, { inventoryId: partSearchResultsNextInventoryId }, { inherit: true })}
					/>
				{/if}
			</div>
		</div>
		<AsrNavTabBar
			{tabs}
			{asr}
		></AsrNavTabBar>
	</div>
	<div class="card-body">
		<uiView></uiView>
	</div>
</div>

<ReportJobModal bind:this={reportJobModal}></ReportJobModal>
<LoadItemModal
	itemTitle="Part"
	itemIdProp="inventoryId"
	itemDisplayProp="tagNumber"
	doSearch={doTagNumberSearch}
	on:lookupError={({ detail: { error } }) => logAndAlert(error, mediator, error.message)}
	on:chooseItem={event => asr.go('app.part', { inventoryId: event.detail.item.inventoryId })}
	bind:this={loadPartModal}
/>

<Modal
	bind:show={unshareableFilesModal.show}
	title="Some Files are Unsupported"
	confirmButtonText={unshareableFilesModal?.share?.files?.length ? 'Share the Remaining Files' : 'Share Without Files'}
	confirmButtonIcon="share-alt"
	on:confirm={() => unshareableFilesModal.share && confirmShare(unshareableFilesModal.share)}
	on:close={() => (unshareableFilesModal.show = false)}
>
	The web platform does not currently support sharing the following files:
	<ul>
		{#each unshareableFilesModal.unshareableFiles ?? [] as file}
			<li>{file}</li>
		{/each}
	</ul>
</Modal>
